<script>
import { Card } from '@components/Card';
import { Banner } from '@components/Banner';

export default {
  name:       'ConfirmDialog',
  components: {
    Card,
    Banner,
  },

  props: {
    resource: {
      type:     Object,
      required: true,
    },
    confirm: {
      type:     Function,
      required: true,
    },
    handleCancel: {
      type:     Function,
      required: false,
    },
    title: {
      type:    String,
      default: '确认操作',
    },
    body: {
      type:    String,
      default: '确认执行此操作?',
    },
  },

  methods: {
    close() {
      if (this.handleCancel) {
        this.handleCancel(this.resource);
      }
      this.$emit('close');
    },

    apply() {
      this.confirm(this.resource);
      this.close();
    },
  },
};
</script>

<template>
  <Card
    class="prompt-rotate"
    :show-highlight-border="false"
  >
    <h4
      slot="title"
      class="text-default-text"
    >
      {{ title }}
    </h4>
    <template #body>
      <Banner
        color="warning"
        :label="body"
        class="mt-20"
      />
    </template>
    <div
      slot="actions"
      class="buttons"
    >
      <button
        class="btn role-secondary mr-10"
        @click="close"
      >
        {{ t('generic.cancel') }}
      </button>

      <button
        class="btn role-primary"
        @click="apply"
      >
        {{ t('generic.confirm') }}
      </button>
    </div>
  </Card>
</template>
<style lang="scss" scoped>
.prompt-rotate {
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
