import {
  CATALOG,
  CONFIG_MAP,
  EVENT,
  HPA,
  INGRESS, MANAGEMENT, NAMESPACE,
  NETWORK_POLICY,
  NODE,
  POD,
  PV,
  PVC, RBAC,
  SECRET,
  SERVICE, SERVICE_ACCOUNT,
  STORAGE_CLASS,
  VIRTUAL_TYPES, WORKLOAD_TYPES,
} from '@shell/config/types';
import {
  PAI_WORKLOAD,
  PAI_WORKLOAD_TYPES,
  PAI_RESOURCES,
  PAI_SERVICE,
  PAI_POD,
  PAI_STORAGE,
  PAI_CHARTS,
  PAI_NODE,
  PAI_CATALOG,
  PAI_CLUSTER,
  PAI_VMSET_POD,
  PAI_EVENT,
  PAI_NAMESPACE,
  PAI_RBAC,
  PAI_SERVICE_ACCOUNT,
  PAI_RBAC_TYPES,
  PAI_SERVICE_GROUP,
  PAI_OVERVIEW,
} from './types';

import {
  AGE, EVENT_TYPE, LAST_SEEN_TIME, MESSAGE,
  NAME as NAME_COL,
  NAMESPACE as NAMESPACE_COL, POD_IMAGES,
  POD_RESTARTS, REASON, SELECTOR, SPEC_TYPE,
  STATE, STORAGE_CLASS_DEFAULT, STORAGE_CLASS_PROVISIONER, TARGET_PORT,
  TYPE,
  WORKLOAD_ENDPOINTS,
  WORKLOAD_HEALTH_SCALE,
  WORKLOAD_IMAGES,
} from '@shell/config/table-headers';
import {
  PAI_ALIAS_COL, PAI_ALIAS_NAME_COL, PAI_AUTH_COL, PAI_NODE_COL, PAI_OBJECT_COL, PAI_PROJECT_COL, PAI_SPEC_TYPE,
} from './table-headers';
import { COMMAND_HASH_MPA } from './settings';

export const PRODUCT_NAME = 'pai';

export function init($plugin, store) {
  const {
    product,
    basicType,
    headers,
    virtualType,
    componentForType,
    weightIconGroup,
    configureType,
  } = $plugin.DSL(store, PRODUCT_NAME);
  const typeStoreMap = {};
  const array = [
    PAI_NODE,
    NODE,
    PAI_EVENT,
    EVENT,
    PAI_POD,
    POD,
    PAI_NAMESPACE,
    NAMESPACE,
    PAI_SERVICE_ACCOUNT,
    SERVICE_ACCOUNT,
    ...Object.values(PAI_WORKLOAD_TYPES),
    ...Object.values(WORKLOAD_TYPES),
    ...Object.values(PAI_RESOURCES),
    ...Object.values(PAI_CATALOG),
    ...Object.values(CATALOG),
    ...Object.values(PAI_SERVICE),
    SERVICE,
    INGRESS,
    HPA,
    NETWORK_POLICY,
    ...Object.values(PAI_RBAC_TYPES),
    ...Object.values(RBAC),
    ...Object.values(PAI_STORAGE),
    STORAGE_CLASS,
    PV,
    PVC,
    CONFIG_MAP,
    SECRET,
  ];

  array.forEach((item) => {
    typeStoreMap[item] = 'cluster';
  });

  product({
    inStore:             'management',
    removable:           false,
    showNamespaceFilter: true,
    typeStoreMap,
    supportRoute:        { name: `${ PRODUCT_NAME }-c-cluster-support` },
    to:                  {
      name:   `${ PRODUCT_NAME }-home`,
      params: { product: PRODUCT_NAME },
    },
  });

  // 集群概览

  basicType([PAI_OVERVIEW]);

  virtualType({
    group:      'Root',
    namespaced: false,
    name:       PAI_OVERVIEW,
    labelKey:   'pai.overview.label',
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-overview`,
      params: { product: PRODUCT_NAME },
    },
    exact: true,
    icon:  'fork',
  });
  // 主机&容器

  basicType([
    PAI_RESOURCES.VMSET,
    PAI_POD,
  ], PAI_VMSET_POD);

  weightIconGroup(PAI_VMSET_POD, 101, true, 'docker');

  virtualType({
    group:      PAI_VMSET_POD,
    name:       PAI_RESOURCES.VMSET,
    ifHaveType: {
      type:  PAI_RESOURCES.VMSET,
      store: 'cluster',
    },
    labelKey: 'pai.menu.vmset',
    weight:   100,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RESOURCES.VMSET,
      },
    },
  });
  virtualType({
    group:    PAI_VMSET_POD,
    name:     PAI_POD,
    labelKey: 'pai.menu.pod',
    weight:   99,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_POD,
      },
    },
  });

  // 工作负载

  basicType([
    PAI_WORKLOAD,
    PAI_WORKLOAD_TYPES.DEPLOYMENT,
    PAI_WORKLOAD_TYPES.STATEFUL_SET,
    PAI_WORKLOAD_TYPES.JOB,
    PAI_WORKLOAD_TYPES.CRON_JOB,
    PAI_WORKLOAD_TYPES.DAEMON_SET,
  ], PAI_WORKLOAD);

  for (const key in PAI_WORKLOAD_TYPES) {
    componentForType(PAI_WORKLOAD_TYPES[key], PAI_WORKLOAD);
  }

  weightIconGroup(PAI_WORKLOAD, 100, true, 'menu');

  virtualType({
    group:          PAI_WORKLOAD,
    name:           PAI_WORKLOAD,
    labelKey:       'pai.menu.workload',
    namespaced:     true,
    weight:         99,
    ifHaveSubTypes: Object.values(PAI_WORKLOAD_TYPES),
    route:          {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: { resource: PAI_WORKLOAD },
    },
    overview: true,
  });

  virtualType({
    group:    PAI_WORKLOAD,
    name:     PAI_WORKLOAD_TYPES.DEPLOYMENT,
    labelKey: 'pai.menu.apps.deployment',
    weight:   98,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_WORKLOAD_TYPES.DEPLOYMENT,
      },
    },
  });

  virtualType({
    group:    PAI_WORKLOAD,
    name:     PAI_WORKLOAD_TYPES.STATEFUL_SET,
    labelKey: 'pai.menu.apps.statefulset',
    weight:   97,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_WORKLOAD_TYPES.STATEFUL_SET,
      },
    },
  });

  virtualType({
    group:    PAI_WORKLOAD,
    name:     PAI_WORKLOAD_TYPES.JOB,
    labelKey: 'pai.menu.batch.job',
    weight:   96,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_WORKLOAD_TYPES.JOB,
      },
    },
  });
  virtualType({
    group:    PAI_WORKLOAD,
    name:     PAI_WORKLOAD_TYPES.CRON_JOB,
    labelKey: 'pai.menu.batch.cronjob',
    weight:   95,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_WORKLOAD_TYPES.CRON_JOB,
      },
    },
  });
  virtualType({
    group:    PAI_WORKLOAD,
    name:     PAI_WORKLOAD_TYPES.DAEMON_SET,
    labelKey: 'pai.menu.apps.daemonset',
    weight:   94,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_WORKLOAD_TYPES.DAEMON_SET,
      },
    },
  });

  virtualType({
    group:    PAI_WORKLOAD,
    name:     PAI_WORKLOAD_TYPES.STATEFUL_SET,
    labelKey: 'pai.menu.apps.statefulset',
    weight:   98,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_WORKLOAD_TYPES.STATEFUL_SET,
      },
    },
  });

  // 服务发现

  basicType([
    PAI_SERVICE.SERVICE,
    PAI_SERVICE.INGRESS,
    PAI_SERVICE.HPA,
    PAI_SERVICE.NETWORK_POLICY,
  ], PAI_SERVICE_GROUP);

  weightIconGroup(PAI_SERVICE_GROUP, 99, true, 'search');

  virtualType({
    group:    PAI_SERVICE_GROUP,
    name:     PAI_SERVICE.SERVICE,
    labelKey: 'pai.menu.service.label',
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_SERVICE.SERVICE,
      },
    },
    weight: 100,
  });

  virtualType({
    group:      PAI_SERVICE_GROUP,
    labelKey:   'pai.menu.service.networking.k8s.io.ingress',
    namespaced: true,
    name:       PAI_SERVICE.INGRESS,
    weight:     99,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_SERVICE.INGRESS,
      },
    },
  });

  virtualType({
    group:      PAI_SERVICE_GROUP,
    labelKey:   'pai.menu.service.autoscaling.horizontalpodautoscaler',
    namespaced: true,
    name:       PAI_SERVICE.HPA,
    weight:     98,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_SERVICE.HPA,
      },
    },
  });

  virtualType({
    group:      PAI_SERVICE_GROUP,
    labelKey:   'pai.menu.service.networking.k8s.io.networkPolicy',
    namespaced: true,
    name:       PAI_SERVICE.NETWORK_POLICY,
    weight:     97,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_SERVICE.NETWORK_POLICY,
      },
    },
  });

  // 存储管理

  basicType([
    PAI_RESOURCES.BACKUP,
    PAI_RESOURCES.RESTORE,
    PAI_STORAGE.STORAGE_CLASS,
    PAI_STORAGE.PV,
    PAI_STORAGE.PVC,
    PAI_STORAGE.SECRET,
    PAI_STORAGE.CONFIG_MAP,
  ], PAI_STORAGE.STORAGE_CLASS);

  weightIconGroup(PAI_STORAGE.STORAGE_CLASS, 97, true, 'file');
  virtualType({
    group:      PAI_STORAGE.STORAGE_CLASS,
    labelKey:   'pai.menu.storage.backup',
    namespaced: true,
    name:       PAI_RESOURCES.BACKUP,
    ifHaveType: {
      type:  PAI_RESOURCES.BACKUP,
      store: 'cluster',
    },
    weight: 102,
    route:  {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RESOURCES.BACKUP,
      },
    },
  });
  headers(PAI_RESOURCES.BACKUP, [STATE, NAME_COL, NAMESPACE_COL, PAI_PROJECT_COL, {
    name:        'includedNamespaces',
    labelKey:    'pai.vmset.namespace',
    value:       `$['spec']['includedNamespaces']`,
    dashIfEmpty: true,
  }, {
    name:     'vmset',
    labelKey: 'pai.vmset.name',
    value:    `$['spec']['labelSelector']['matchLabels']['com.tdology.virt.vmsets']`,
  }, AGE]);
  configureType(PAI_RESOURCES.BACKUP, { isCreatable: false });
  virtualType({
    group:      PAI_STORAGE.STORAGE_CLASS,
    labelKey:   'pai.menu.storage.restore',
    namespaced: true,
    name:       PAI_RESOURCES.RESTORE,
    ifHaveType: {
      type:  PAI_RESOURCES.RESTORE,
      store: 'cluster',
    },
    weight: 101,
    route:  {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RESOURCES.RESTORE,
      },
    },
  });
  headers(PAI_RESOURCES.RESTORE, [STATE, NAME_COL, NAMESPACE_COL, {
    name:         'ProjectDisplayName',
    labelKey:     'pai.vmset.project',
    formatter:    'RestoreProject',
    delayLoading: true,
  }, {
    name:         'BackupNamespace',
    labelKey:     'pai.vmset.namespace',
    formatter:    'RestoreNamespace',
    delayLoading: true,
  }, {
    name:     'vmset',
    labelKey: 'pai.vmset.name',
    value:    `$['spec']['labelSelector']['matchLabels']['com.tdology.virt.vmsets']`,
  }, AGE]);
  configureType(PAI_RESOURCES.RESTORE, { isCreatable: false });
  virtualType({
    group:      PAI_STORAGE.STORAGE_CLASS,
    labelKey:   'pai.menu.storage.storage.k8s.io.storageclass',
    namespaced: true,
    name:       PAI_STORAGE.STORAGE_CLASS,
    weight:     100,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_STORAGE.STORAGE_CLASS,
      },
    },
  });

  virtualType({
    group:      PAI_STORAGE.STORAGE_CLASS,
    labelKey:   'pai.menu.storage.persistentvolume',
    namespaced: true,
    name:       PAI_STORAGE.PV,
    weight:     99,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_STORAGE.PV,
      },
    },
  });

  virtualType({
    group:      PAI_STORAGE.STORAGE_CLASS,
    labelKey:   'pai.menu.storage.persistentvolumeclaim',
    namespaced: true,
    name:       PAI_STORAGE.PVC,
    weight:     98,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_STORAGE.PVC,
      },
    },
  });

  virtualType({
    group:      PAI_STORAGE.STORAGE_CLASS,
    labelKey:   'pai.menu.storage.secret',
    namespaced: true,
    name:       PAI_STORAGE.SECRET,
    weight:     97,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_STORAGE.SECRET,
      },
    },
  });

  virtualType({
    group:      PAI_STORAGE.STORAGE_CLASS,
    labelKey:   'pai.menu.storage.configmap',
    namespaced: true,
    name:       PAI_STORAGE.CONFIG_MAP,
    weight:     96,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_STORAGE.CONFIG_MAP,
      },
    },
  });

  // 应用市场

  basicType([
    PAI_CHARTS,
    PAI_CATALOG.CLUSTER_REPO,
    PAI_CATALOG.APP,
    PAI_CATALOG.OPERATION,
  ], PAI_CHARTS);

  weightIconGroup(PAI_CHARTS, 96, true, 'marketplace');

  virtualType({
    group:    PAI_CHARTS,
    name:     PAI_CHARTS,
    labelKey: 'pai.menu.catalog.charts',
    weight:   100,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-apps-charts`,
      params: { product: PRODUCT_NAME },
    },
  });

  configureType(PAI_RESOURCES.VM_IMAGE, {
    location: {
      name:   'pai-c-cluster-apps-charts',
      params: { product: PRODUCT_NAME },
      hash:   COMMAND_HASH_MPA.vm,
    },
  });

  virtualType({
    group:      PAI_CHARTS,
    labelKey:   'pai.menu.catalog.cattle.io.clusterrepo',
    namespaced: true,
    name:       PAI_CATALOG.CLUSTER_REPO,
    ifHaveType: {
      type:  CATALOG.CLUSTER_REPO,
      store: 'cluster',
    },
    weight: 99,
    route:  {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_CATALOG.CLUSTER_REPO,
      },
    },
  });
  virtualType({
    group:      PAI_CHARTS,
    labelKey:   'pai.menu.catalog.cattle.io.app',
    namespaced: true,
    name:       PAI_CATALOG.APP,
    ifHaveType: {
      type:  CATALOG.APP,
      store: 'cluster',
    },
    weight: 98,
    route:  {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_CATALOG.APP,
      },
    },
  });
  virtualType({
    group:      PAI_CHARTS,
    labelKey:   'pai.menu.catalog.cattle.io.operation',
    namespaced: true,
    name:       PAI_CATALOG.OPERATION,
    ifHaveType: {
      type:  CATALOG.OPERATION,
      store: 'cluster',
    },
    weight: 97,
    route:  {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_CATALOG.OPERATION,
      },
    },
  });

  // 集群管理

  basicType([
    VIRTUAL_TYPES.PROJECT_NAMESPACES,
    PAI_NODE,
    VIRTUAL_TYPES.CLUSTER_MEMBERS,
    PAI_EVENT,
  ], PAI_CLUSTER);

  weightIconGroup(PAI_CLUSTER, 95, true, 'cluster-management');

  virtualType({
    group:            PAI_CLUSTER,
    labelKey:         'pai.menu.cluster.projectNamespaces',
    namespaced:       false,
    ifRancherCluster: true,
    name:             VIRTUAL_TYPES.PROJECT_NAMESPACES,
    weight:           99,
    route:            {
      name:   `${ PRODUCT_NAME }-c-cluster-projectsnamespaces`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_NAMESPACE,
      },
    },
    exact: true,
  });
  virtualType({
    group:      PAI_CLUSTER,
    labelKey:   'pai.menu.cluster.members',
    namespaced: false,
    name:       VIRTUAL_TYPES.CLUSTER_MEMBERS,
    ifHaveType: MANAGEMENT.PROJECT,
    weight:     98,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-members`,
      params: { product: PRODUCT_NAME },
    },
  });
  virtualType({
    group:      PAI_CLUSTER,
    labelKey:   'pai.menu.cluster.node',
    namespaced: false,
    name:       PAI_NODE,
    weight:     97,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_NODE,
      },
    },
  });
  virtualType({
    group:      PAI_CLUSTER,
    labelKey:   'pai.menu.cluster.events',
    namespaced: false,
    name:       PAI_EVENT,
    weight:     96,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_EVENT,
      },
    },
  });
  configureType(PAI_NODE, {
    isCreatable: false,
    isEditable:  false,
  });
  headers(SERVICE, [STATE, NAME_COL, NAMESPACE_COL, TARGET_PORT, SELECTOR, PAI_SPEC_TYPE, AGE]);
  headers(PAI_WORKLOAD, [STATE, NAME_COL, NAMESPACE_COL, TYPE, WORKLOAD_IMAGES, WORKLOAD_ENDPOINTS, POD_RESTARTS, AGE, WORKLOAD_HEALTH_SCALE]);
  headers(POD, [STATE, NAME_COL, NAMESPACE_COL, POD_IMAGES, {
    name:      'podType',
    labelKey:  'tableHeaders.podType',
    formatter: 'PodType',
    sort:      'podType',
    width:     40,
  }, 'Ready', 'Restarts', 'IP', PAI_NODE_COL, AGE]);
  headers(EVENT, [STATE, {
    ...LAST_SEEN_TIME,
    defaultSort: true,
  }, EVENT_TYPE, REASON, PAI_OBJECT_COL, 'Subobject', 'Source', MESSAGE, 'First Seen', 'Count', NAME_COL, NAMESPACE_COL]);
  headers(STORAGE_CLASS, [STATE, NAME_COL, PAI_ALIAS_COL, STORAGE_CLASS_PROVISIONER, PAI_AUTH_COL, STORAGE_CLASS_DEFAULT, AGE]);

  // 集群管理

  basicType([
    PAI_SERVICE_ACCOUNT,
    PAI_RBAC_TYPES.ROLE,
    PAI_RBAC_TYPES.ROLE_BINDING,
    PAI_RBAC_TYPES.CLUSTER_ROLE,
    PAI_RBAC_TYPES.CLUSTER_ROLE_BINDING,
  ], PAI_RBAC);

  weightIconGroup(PAI_RBAC, 94, true, 'user');

  virtualType({
    group:    PAI_RBAC,
    labelKey: 'pai.menu.rbac.serviceAccount',
    name:     PAI_SERVICE_ACCOUNT,
    weight:   99,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_SERVICE_ACCOUNT,
      },
    },
  });

  virtualType({
    group:    PAI_RBAC,
    labelKey: 'pai.menu.rbac.role',
    name:     PAI_RBAC_TYPES.ROLE,
    weight:   98,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RBAC_TYPES.ROLE,
      },
    },
  });

  virtualType({
    group:    PAI_RBAC,
    labelKey: 'pai.menu.rbac.roleBinding',
    name:     PAI_RBAC_TYPES.ROLE_BINDING,
    weight:   97,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RBAC_TYPES.ROLE_BINDING,
      },
    },
  });

  virtualType({
    group:    PAI_RBAC,
    labelKey: 'pai.menu.rbac.clusterRole',
    name:     PAI_RBAC_TYPES.CLUSTER_ROLE,
    weight:   96,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RBAC_TYPES.CLUSTER_ROLE,
      },
    },
  });

  virtualType({
    group:    PAI_RBAC,
    labelKey: 'pai.menu.rbac.clusterRoleBinding',
    name:     PAI_RBAC_TYPES.CLUSTER_ROLE_BINDING,
    weight:   95,
    route:    {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RBAC_TYPES.CLUSTER_ROLE_BINDING,
      },
    },
  });

  // 网络管理

  basicType([
    PAI_RESOURCES.NAD,
    'lb',
  ], 'pai.network');
  weightIconGroup('pai.network', 93, true, 'globe');

  virtualType({
    group:      'pai.network',
    labelKey:   'pai.menu.k8s.cni.cncf.io.networkattachmentdefinition',
    namespaced: false,
    name:       PAI_RESOURCES.NAD,
    ifHaveType: {
      type:  PAI_RESOURCES.NAD,
      store: 'cluster',
    },
    weight: 97,
    route:  {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: PAI_RESOURCES.NAD,
      },
    },
  });

  virtualType({
    group:      'pai.network',
    labelKey:   'pai.menu.lb',
    namespaced: false,
    name:       'lb',
    weight:     96,
    route:      { name: `${ PRODUCT_NAME }-c-cluster-lb` },
  });

  headers(PAI_RESOURCES.NAD, [
    STATE,
    PAI_ALIAS_NAME_COL,
    NAMESPACE_COL,
    {
      name:          'hostNetwork',
      labelKey:      'pai.list.network.hostNetwork',
      formatter:     'Network',
      formatterOpts: { type: 'hostNetwork' },
      value:         'spec.config',
    },
    {
      name:          'networkType',
      labelKey:      'pai.list.network.networkType',
      formatter:     'Network',
      formatterOpts: { type: 'networkType' },
      value:         'spec.config',
    },
    {
      name:          'assignmentType',
      labelKey:      'pai.list.network.assignmentType',
      formatter:     'Network',
      formatterOpts: { type: 'assignmentType' },
      value:         'spec.config',
    },
    AGE,
  ]);
}
