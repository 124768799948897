import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import SteveModel from '@shell/plugins/steve/steve-class';
import { MANAGEMENT, NAMESPACE, SCHEMA } from '@shell/config/types';
import { clone } from '@shell/utils/object';
import { createYaml } from '@shell/utils/create-yaml';
import { PAI_RESOURCES } from '~/pkg/pai/config/types';
import { PVC_LABELS } from '~/pkg/pai/config/labels-annotations';

export default class extends PaiLocationMixin(SteveModel) {
  get _availableActions() {
    const out = super._availableActions;

    out.unshift({
      action:  'restore',
      enabled: this.status?.phase === 'Completed',
      icon:    'icon icon-copy',
      label:   this.t('pai.detail.vmset.restore'),
    });

    return out;
  }

  restore(resource = this) {
    this.$dispatch('promptModal', {
      component:      'RestoreDialog',
      componentProps: { value: resource },
    });
  }

  async remove(resource = this) {
    const time = new Date().valueOf();
    if (this.$getters['schemaFor'](PAI_RESOURCES.BACKUP)) {
      this.backUps = await this.$dispatch('findAll', { type: PAI_RESOURCES.BACKUP });
    }
    const vmName = resource.spec.labelSelector?.matchLabels ? resource.spec.labelSelector.matchLabels[PVC_LABELS.MOUNT_VM] : null;
    const deleteBackupForm = { name: vmName ? `deletebackup-vm-${ vmName }-${ time }` : `deletebackup-${ time }` };
    const deleteBackupYaml = {
      apiVersion: 'velero.io/v1',
      kind:       'DeleteBackupRequest',
      metadata:   {
        name:      '', // # 删除备份的名称，前端自动生成即可
        namespace: 'velero',
        labels:    { deleteBy: '' // 当前操作用户名 # 删除备份者
        }
      },

      spec: { backupName: '', // # 备份名称
      }
    };

    const currentList = this.backUps.find(i => resource.metadata.name === i.metadata.name);
    const schemas = this.$getters[`all`](SCHEMA);
    const backUpSchema = schemas.filter(v => v.id === 'velero.io.deletebackuprequest')[0];
    const deleteOpt = {
      method:  'post',
      headers: {
        'content-type': 'application/yaml',
        accept:         'application/json',
      },
      data: {},
      url:  'v1/velero.io.deletebackuprequest',
    };

    deleteBackupYaml.metadata.name = deleteBackupForm.name;
    deleteBackupYaml.metadata.labels.deleteBy = currentList.metadata.annotations.createby;
    deleteBackupYaml.spec.backupName = currentList.metadata.name;
    deleteOpt.data = this.toDeleteBackupYaml(deleteBackupYaml);
    await backUpSchema.$ctx.dispatch('request', deleteOpt);
  }

  toDeleteBackupYaml(value) {
    const schemas = this.$getters[`all`](SCHEMA);
    const clonedResource = clone(value);

    return createYaml(schemas, 'velero.io.deletebackuprequest', clonedResource);
  }

  get name() {
    return this.metadata.labels.displayName;
  }

  get projectId() {
    const inStore = this.$rootGetters['currentProduct'].inStore;
    const all = this.$rootGetters[`${ inStore }/all`](NAMESPACE);

    if (this.spec.includedNamespaces && this.spec.includedNamespaces.length > 0) {
      const namespace = all.find(namespace => namespace.id === this.spec.includedNamespaces[0]);

      if (namespace) {
        return namespace.projectId;
      }
    }

    return null;
  }

  get projectDisplayName() {
    const clusterId = this.$rootGetters['currentCluster']?.id;

    if (this.projectId) {
      const project = this.$rootGetters['management/byId'](MANAGEMENT.PROJECT, `${ clusterId }/${ this.projectId }`);

      return project?.spec?.displayName;
    }

    return null;
  }
}
