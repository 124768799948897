<script>
import day from 'dayjs';
import { NORMAN, SCHEMA } from '@/shell/config/types';
import { clone } from '@/shell/utils/object';
import { createYaml } from '@/shell/utils/create-yaml';
import { mapGetters } from 'vuex';
import { PAI_RESOURCES } from '../../../config/types';
export default {
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    const params = this.$route.params;
    const restoreYaml = {
      apiVersion: 'velero.io/v1',
      kind:       'Restore',
      metadata:   {
        annotations: { createby: '' }, // # 添加此annotation，当前操作用户的user name
        labels:      { displayName: '' },
        name:        '', // # 修改这个，可以自定义一个固定的格式,例如restore-ns-{ns name}-时间戳
        namespace:   'velero'
      },

      spec: {
        backupName:         '', // # 修改这个，对应backup名字
        labelSelector:      { matchLabels: { 'com.tdology.virt.vmsets': this.value.metadata.name } },
        includedNamespaces: '',
        excludedResources:
            ['nodes', 'events', 'events.events.k8s.io', 'backups.velero.io', 'restores.velero.io', 'resticrepositories.velero.io', 'csinodes.storage.k8s.io', 'volumeattachments.storage.k8s.io', 'backuprepositories.velero.io'],
      }
    };
    // 当时时间戳
    const time = new Date().valueOf();
    const backUpYaml = {
      apiVersion: 'velero.io/v1',
      kind:       'Backup',
      metadata:   {
        annotations: { createby: '' },
        labels:      {
          'velero.io/storage-location': 'default',
          displayName:                  ''
        },
        name:      '',
        namespace: 'velero',
      },

      spec: {
        csiSnapshotTimeout:       '30m0s',
        defaultVolumesToFsBackup: false,
        includedNamespaces:       '',
        labelSelector:            { matchLabels: { 'com.tdology.virt.vmsets': '' } },
        storageLocation:          'default',
        ttl:                      '800h0m0s',
        volumeSnapshotLocations:  ['default'],
      },
    };
    const deleteBackupYaml = {
      apiVersion: 'velero.io/v1',
      kind:       'DeleteBackupRequest',
      metadata:   {
        annotations: { createby: '' },
        name:        '', // # 删除备份的名称，前端自动生成即可
        namespace:   'velero',
        labels:      { deleteBy: '' // 当前操作用户名 # 删除备份者
        }
      },

      spec: { backupName: '', // # 备份名称
      }
    };

    return {
      params,
      backUpYaml,
      deleteBackupYaml,
      restoreDialogVisible: false,
      restoreYaml,
      restoreForm:          { name: `restore-ns-${ this.value.metadata.namespace }-${ time }` },
      backUpForm:           { name: `backup-vm-${ this.value.metadata.name }-${ time }` },
      deleteBackupForm:     { name: `deletebackup-vm-${ this.value.metadata.name }` },
      restoreData:          [],
      backUps:              [],
      filterValue:          '',
      backUpDialogVisible:  false,
      backupBy:             '',
      currentBackupName:    ''
    };
  },
  async fetch() {
    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.BACKUP)) {
      this.backUps = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.BACKUP });
    }
    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.RESTORE)) {
      this.restoreData = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.RESTORE });
    }
  },
  computed: {
    ...mapGetters([{ t: 'i18n/t' }, 'isRancher']),
    newTableData() {
      const dateFormat = 'YYYY-MM-DD';
      const timeFormat = 'HH:mm:ss';
      const tableData = [];

      this.backUps.map((item, index) => {
        if (item.spec?.labelSelector?.matchLabels['com.tdology.virt.vmsets'] === this.value.metadata.name ) {
          tableData.push({
            name:               item.metadata?.name,
            projectDisplayName: item.projectDisplayName,
            displayName:        item.metadata?.labels?.displayName,
            namespace:          item.spec.includedNamespaces[0],
            status:             item.status?.phase,
            creator:            item.metadata?.annotations?.createby,
            startTime:          day(item.status?.startTimestamp).format(`${ dateFormat } ${ timeFormat }`),
            endTime:            item.status?.completionTimestamp ? day(item.status?.completionTimestamp).format(`${ dateFormat } ${ timeFormat }`) : '-',
            deadTime:           day(item.status?.expiration).format(`${ dateFormat } ${ timeFormat }`),
          });
        }
      });

      return tableData;
    },
    backupStatus() {
      return [
        {
          name: 'Active', label: '新创建', tip: '表示备份已经创建但还没有被 BackupController 处理'
        },
        {
          name: 'New', label: '新创建', tip: '表示备份已经创建但还没有被 BackupController 处理'
        },
        {
          name: 'FailedValidation', label: '验证失败', tip: '表示备份未通过控制器的验证，因此不会运行'
        },
        {
          name: 'InProgress', label: '进行中', tip: '表示备份当前正在执行'
        },
        {
          name: 'WaitingForPluginOperations', label: '等待插件操作', tip: '表示备份 Kubernetes 资源、创建快照等异步插件操作成功，当前正在上传快照数据或其他插件操作仍在进行中，备份尚不可用'
        },
        {
          name: 'WaitingForPluginOperationsPartiallyFailed', label: '等待插件操作（部分失败）', tip: '表示备份 Kubernetes 资源、创建快照等异步插件操作部分失败（最终阶段将是 PartiallyFailed），当前正在上传快照数据或其他插件操作仍在进行中，备份尚不可用'
        },
        {
          name: 'Finalizing', label: '等待最终更新中', tip: '表示 Kubernetes 资源的备份、创建快照和其他异步插件操作成功，快照上传和其他插件操作现已完成，但备份正在等待异步操作期间修改的资源的最终更新，备份尚不可用'
        },
        {
          name: 'FinalizingPartiallyFailed', label: '最终更新中（部分失败）', tip: '表示备份 Kubernetes 资源、创建快照和其他异步插件操作成功，快照上传和其他插件操作现已完成，但在备份或异步操作处理过程中出现一个或多个错误，备份正在等待最终更新在异步操作期间修改的资源，备份尚不可用'
        },
        {
          name: 'Completed', label: '已完成', tip: '表示备份已完成且没有错误'
        },
        {
          name: 'PartiallyFailed', label: '部分失败', tip: '表示备份已运行完成，但在备份单个项目时遇到 1+ 个错误'
        },
        {
          name: 'Failed', label: '失败', tip: '表示备份已运行但遇到错误，导致无法成功完成'
        },
        {
          name: 'Deleting', label: '正在删除', tip: '表示正在删除备份及其所有关联数据'
        },
      ];
    },
    principal() {
      if (this.isRancher) {
        return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
      } else {
        return { loginName: 'admin' };
      }
    },
  },
  methods: {
    restoreVm(value) {
      const time = new Date().valueOf();

      this.backupBy = value.creator;
      this.currentBackupName = value.name;
      this.restoreDialogVisible = true;
      this.restoreForm = { name: `restore-ns-${ this.value.metadata.namespace }-${ time }` };
    },
    async onRestore() {
      if (this.restoreForm.name === '') {
        this.$message.error(this.t('pai.detail.vmset.enterName'));

        return;
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

        return;
      } else if (/[\u4E00-\u9FA5]/g.test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.verifyChinese'));

        return;
      } else if ((/[A-Z]/g).test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.notUppercase'));

        return;
      }
      if (this.restoreData.length > 0) {
        for (const item in this.restoreData) {
          if (this.restoreData[item].spec?.labelSelector?.matchLabels['com.tdology.virt.vmsets'] === this.value.metadata.name) {
            if (this.restoreData[item].metadata.labels.displayName === this.restoreForm.name) {
              this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

              return;
            }
          }
        }
      }
      const inStore = this.$store.getters['currentStore'](this.value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const restoreSchema = schemas.filter(v => v.id === PAI_RESOURCES.RESTORE)[0];

      this.restoreDialogVisible = false;
      const opt = {
        method:  'post',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        url:  `v1/${ PAI_RESOURCES.RESTORE }`,
      };
      const time = new Date().valueOf();

      this.restoreYaml.metadata.annotations.createby = this.backupBy;
      this.restoreYaml.metadata.name = `restore-ns-${ this.restoreForm.name }-${ time }`;
      this.restoreYaml.metadata.labels.displayName = this.restoreForm.name;
      this.restoreYaml.spec.backupName = this.currentBackupName;
      this.restoreYaml.spec.includedNamespaces = [this.value.metadata.namespace];
      try {
        opt.data = this.toRestoreYaml(this.restoreYaml);
        await restoreSchema.$ctx.dispatch('request', opt);
        this.$message({
          type:    'success',
          message: `${ this.t('pai.labels.success') }`,
        });
      } catch (e) {
        this.$message({
          type:    'warning',
          message: e.message
        });
      }
    },
    toRestoreYaml(value) {
      const inStore = this.$store.getters['currentStore'](value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const clonedResource = clone(value);

      return createYaml(schemas, PAI_RESOURCES.RESTORE, clonedResource);
    },
    deleteBackup(value) {
      this.$confirm(this.t('pai.detail.vmset.confirmDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(async() => {
        const time = new Date().valueOf();
        const currentList = this.backUps.find(i => value.name === i.metadata.name);

        const inStore = this.$store.getters['currentStore'](this.value);
        const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
        const backUpSchema = schemas.filter(v => v.id === PAI_RESOURCES.DELETE_BACKUP_REQUEST)[0];
        const deleteOpt = {
          method:  'post',
          headers: {
            'content-type': 'application/yaml',
            accept:         'application/json',
          },
          data: {},
          url:  `v1/${ PAI_RESOURCES.DELETE_BACKUP_REQUEST }`,
        };
        this.deleteBackupYaml.metadata.name = `${this.deleteBackupForm.name}-${ time }`;
        this.deleteBackupYaml.metadata.labels.deleteBy = currentList.metadata?.annotations?.createby;
        this.deleteBackupYaml.spec.backupName = currentList.metadata.name;
        deleteOpt.data = this.toDeleteBackupYaml(this.deleteBackupYaml);
        await backUpSchema.$ctx.dispatch('request', deleteOpt);
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteSuccess')
        });
      }).catch((error) => {
        console.log(error);
        this.$message({
          type:    'info',
          message: this.t('pai.detail.vmset.cancelDelete')
        });
      });
    },
    toDeleteBackupYaml(value) {
      const inStore = this.$store.getters['currentStore'](value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const clonedResource = clone(value);

      return createYaml(schemas, PAI_RESOURCES.DELETE_BACKUP_REQUEST, clonedResource);
    },
    backupShow() {
      const time = new Date().valueOf();

      this.backUpForm = { name: `backup-vm-${ this.value.metadata.name }-${ time }` };
      this.backUpDialogVisible = true;
    },
    async onBackUp() { // 备份
      if (this.backUpForm.name === '') {
        this.$message.error(this.t('pai.detail.vmset.enterName'));

        return;
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.backUpForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

        return;
      } else if (/[\u4E00-\u9FA5]/g.test(this.backUpForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.verifyChinese'));

        return;
      } else if ((/[A-Z]/g).test(this.backUpForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.notUppercase'));

        return;
      }
      for (const item in this.backUps) {
        if (this.backUps[item].spec?.labelSelector?.matchLabels['com.tdology.virt.vmsets'] === this.value.metadata.name) {
          if (this.backUps[item].metadata.labels.displayName === this.backUpForm.name) {
            this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

            return;
          }
        }
      }
      const inStore = this.$store.getters['currentStore'](this.value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const backUpSchema = schemas.filter(v => v.id === PAI_RESOURCES.BACKUP)[0];

      this.backUpDialogVisible = false;
      const opt = {
        method:  'post',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        url:  `v1/${ PAI_RESOURCES.BACKUP }`,
      };
      const time = new Date().valueOf();

      this.backUpYaml.metadata.annotations.createby = this.principal.loginName;
      this.backUpYaml.metadata.name = `backup-vm-${ this.backUpForm.name }-${ time }`;
      this.backUpYaml.metadata.labels.displayName = this.backUpForm.name;
      this.backUpYaml.spec.includedNamespaces = [this.value.metadata.namespace];
      this.backUpYaml.spec.labelSelector.matchLabels['com.tdology.virt.vmsets'] = this.value.metadata.name;
      opt.data = this.toBackupYaml(this.backUpYaml);
      try {
        await backUpSchema.$ctx.dispatch('request', opt);
        this.$message.success(this.t('pai.detail.vmset.backuping'));
      } catch (e) {
        console.log(e);
        this.$message.error(this.t('pai.detail.vmset.backupError'));
      }
    },
    toBackupYaml(value) {
      const inStore = this.$store.getters['currentStore'](value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const clonedResource = clone(value);

      return createYaml(schemas, PAI_RESOURCES.BACKUP, clonedResource);
    },
  }
};

</script>

<template>
  <el-card style="margin:10px">
    <el-row>
      <el-col :span="24">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <el-button
              class="btn role-primary"
              @click="backupShow"
            >
              <i class="el-icon-plus" /> {{ t('pai.detail.vmset.tab.backUpManagement.createBackUp') }}
            </el-button>
          </div>
          <el-input
            v-model="filterValue"
            clearable
            :placeholder="t('pai.detail.vmset.filter')"
            prefix-icon="el-icon-search"
            size="small"
          />
          <el-dialog
            :title="t('pai.detail.vmset.tab.backUpManagement.backUpVm')"
            :visible.sync="backUpDialogVisible"
            width="500px"
          >
            <el-descriptions
              :column="1"
              :colon="false"
            >
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.backUpManagement.name') }}
                </template>
                <el-input
                  v-model="backUpForm.name"
                />
              </el-descriptions-item>
            </el-descriptions>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="backUpDialogVisible = false">{{ t('pai.detail.vmset.cancel') }}</el-button>
              <el-button
                type="primary"
                @click="onBackUp"
              >{{ t('pai.detail.vmset.confirm') }}</el-button>
            </span>
          </el-dialog>
        </div>
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? newTableData.filter(item => item.displayName.includes(filterValue)): newTableData"
          class="backUpTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
            width="50"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.backUpManagement.name')"
            width="170"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.displayName"
                effect="light"
              >
                <span
                  style="width: 180px; overflow:hidden; white-space: nowrap; text-overflow: ellipsis;"
                >{{ scope.row.displayName }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isRancher"
            prop="projectDisplayName"
            :label="t('pai.detail.vmset.tab.backUpManagement.projectName')"
          />
          <el-table-column
            prop="namespace"
            :label="t('pai.detail.vmset.tab.backUpManagement.namespaceName')"
          />
          <el-table-column
            :label="t('pai.detail.vmset.status')"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in backupStatus"
                :key="index"
              >
                <span v-if="item.name === scope.row.status">
                  {{ item.label }}
                  <el-tooltip
                    :content="item.tip"
                    placement="top"
                    effect="light"
                  >
                    <img src="@pkg/pai/assets/images/overview/tooltips.svg">
                  </el-tooltip>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="creator"
            :label="t('pai.detail.vmset.tab.backUpManagement.creator')"
          />
          <el-table-column
            prop="startTime"
            :label="t('pai.detail.vmset.startTime')"
            width="180"
          />
          <el-table-column
            prop="endTime"
            :label="t('pai.detail.vmset.tab.backUpManagement.endTime')"
            width="180"
          />
          <el-table-column
            prop="deadTime"
            :label="t('pai.detail.vmset.tab.backUpManagement.deadTime')"
            width="180"
          />
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="200"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.status !== 'Completed'"
                @click="restoreVm(scope.row)"
              >
                <i class="el-icon-refresh-left" /> {{ t('pai.detail.vmset.tab.backUpManagement.resume') }}
              </el-button>
              <el-button
                type="text"
                size="small"
                :disabled="!scope.row.status || scope.row.status === 'InProgress' || scope.row.status === 'Deleting' || scope.row.status === 'WaitingForPluginOperations' || scope.row.status === 'WaitingForPluginOperationsPartiallyFailed' || scope.row.status === 'Finalizing' || scope.row.status === 'FinalizingPartiallyFailed'"
                @click="deleteBackup(scope.row)"
              >
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="t('pai.detail.vmset.tab.backUpManagement.restoreVm')"
          :visible.sync="restoreDialogVisible"
          width="500px"
        >
          <el-descriptions
            :column="1"
            :colon="false"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.backUpManagement.restoreName') }}
              </template>
              <el-input
                v-model="restoreForm.name"
              />
            </el-descriptions-item>
          </el-descriptions>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button @click="restoreDialogVisible = false">{{ t('pai.detail.vmset.cancel') }}</el-button>
            <el-button
              type="primary"
              @click="onRestore"
            >{{ t('pai.detail.vmset.confirm') }}</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.backUpTable{
  padding: 0 20px;
}
.el-input {
  border: none;
  border: 2px solid #5084AA;
  border-radius: 5px;
  width: 215px;
}
.el-input{
  width: 300px;
}
::v-deep .el-descriptions-item__label {
  width: 100px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
</style>
