import { FLEET } from '@shell/config/types';
import { STATE, NAME as NAME_COL, AGE } from '@shell/config/table-headers';
import { gitRepoGraphConfig } from '@shell/pages/c/_cluster/fleet/GitRepoGraphConfig';
import { PAI_RBAC } from './types';

export const PRODUCT_NAME = 'deploy';
export const CHART_NAME = 'fleet';

export function init($plugin, store) {
  const {
    product,
    basicType,
    weightType,
    configureType,
    headers,
    virtualType,
    weightIconGroup
  } = $plugin.DSL(store, PRODUCT_NAME);

  product({
    inStore:               'management',
    removable:             false,
    showNamespaceFilter:   true,
    hideKubeShell:         true,
    hideKubeConfig:        true,
    showClusterSwitcher:   true,
    hideCopyConfig:        true,
    hideNamespaceLocation: true,
    to:                    {
      name:   `${ PRODUCT_NAME }-c-cluster-deploy`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.DASHBOARD,
        cluster:  'local'
      }
    },
  });

  virtualType({
    labelKey:   'pai.menu.deploy.dashboard',
    group:      'Root',
    namespaced: false,
    name:       FLEET.DASHBOARD,
    weight:     110,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-deploy`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.DASHBOARD,
        cluster:  'local'
      }
    },
    exact: true,
    icon:  'dashboard',
  });

  virtualType({
    labelKey:   'pai.menu.deploy.gitRepo',
    group:      'Root',
    namespaced: false,
    name:       FLEET.GIT_REPO,
    weight:     109,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.GIT_REPO,
        cluster:  'local'
      }
    },
    icon: 'rio',
  });

  basicType([
    FLEET.DASHBOARD,
    FLEET.CLUSTER,
    FLEET.CLUSTER_GROUP,
    FLEET.GIT_REPO,
  ]);

  virtualType({
    labelKey:   'pai.menu.deploy.cluster',
    group:      'Root',
    namespaced: false,
    name:       FLEET.CLUSTER,
    weight:     108,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.CLUSTER,
        cluster:  'local'
      }
    },
    icon: 'cluster'
  });
  virtualType({
    labelKey:   'pai.menu.deploy.clusterGroup',
    group:      'Root',
    namespaced: false,
    name:       FLEET.CLUSTER_GROUP,
    weight:     107,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.CLUSTER_GROUP,
        cluster:  'local'
      }
    },
    icon: 'list-flat'
  });

  configureType(FLEET.CLUSTER, { isCreatable: false });
  configureType(FLEET.GIT_REPO, {
    showListMasthead: false, hasGraph: true, graphConfig: gitRepoGraphConfig
  });

  basicType([
    FLEET.WORKSPACE,
    FLEET.BUNDLE,
    FLEET.TOKEN,
  ], 'advanced');
  weightIconGroup('advanced', 94, true, 'more');
  virtualType({
    labelKey:   'pai.menu.deploy.workspace',
    group:      'Root',
    namespaced: false,
    name:       FLEET.WORKSPACE,
    weight:     106,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.WORKSPACE,
        cluster:  'local'
      }
    },
  });
  virtualType({
    labelKey:   'pai.menu.deploy.token',
    group:      'Root',
    namespaced: false,
    name:       FLEET.TOKEN,
    weight:     105,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.TOKEN,
        cluster:  'local'
      }
    },
  });
  virtualType({
    labelKey:   'pai.menu.deploy.bundle',
    group:      'Root',
    namespaced: false,
    name:       FLEET.BUNDLE,
    weight:     104,
    route:      {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        resource: FLEET.BUNDLE,
        cluster:  'local'
      }
    },
  });

  headers(FLEET.WORKSPACE, [
    STATE,
    NAME_COL,
    {
      name:      'gitRepos',
      labelKey:  'tableHeaders.gitRepos',
      value:     'counts.gitRepos',
      sort:      'counts.gitRepos',
      formatter: 'Number',
    },
    {
      name:      'clusters',
      labelKey:  'tableHeaders.clusters',
      value:     'counts.clusters',
      sort:      'counts.clusters',
      formatter: 'Number',
    },
    {
      name:      'clusterGroups',
      labelKey:  'tableHeaders.clusterGroups',
      value:     'counts.clusterGroups',
      sort:      'counts.clusterGroups',
      formatter: 'Number',
    },
    AGE
  ]);
}
