<script>
import ViewConfig from './components/view';
export default {
  layout:     'pai/default',
  components: { ViewConfig },
  fetch() {
    this.$store.dispatch('pai-common/getServices');
    this.$store.dispatch('pai-common/getStatus');
  },
  data() {
    return {
      currentTime: '', currentPage: 'view', services: [],
    };
  },
  /*  methods: {
    formatTime,
    getTime() {
      const timeDate = new Date();
      const week = timeDate.getDay();
      const weekArr = [
        '周日',
        '周一',
        '周二',
        '周三',
        '周四',
        '周五',
        '周六',
      ];

      this.currentTime = `${ formatTime(timeDate) } ${ weekArr[week] }`;
    }
  }, */
/*  mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      this.getTime();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  } */
};
</script>

<template>
  <div class="page">
    <!--    <div class="header">
      <div>运行时间：{{}}</div>
      <div class="router">
        <div><i class="el-icon-full-screen" />进入大屏</div>
        <div @click="currentPage='view'">
          <i class="el-icon-setting" />视图配置
        </div>
        <div @click="currentPage='load'">
          <i class="el-icon-sort" />切换至链路负载
        </div>
      </div>
      <div>{{ currentTime }}</div>
    </div>-->
    <div class="header">
      <div>{{ t('pai.menu.lb') }}</div>
    </div>
    <div class="body">
      <div v-show="currentPage === 'view'">
        <ViewConfig />
      </div>
      <!--      <div v-show="currentPage === 'load'">
        <Load />
      </div>-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page{
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    div {
      margin: 0 10px;

      i {
        margin-right: 10px;
      }
    }

    .router {
      width: 600px;
      display: flex;
      justify-content: space-evenly;
      color: #2484D2;

      div{
        cursor: pointer;
        padding: 5px;
      }
    }
  }
}

</style>
