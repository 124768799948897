import SteveModel from '@shell/plugins/steve/steve-class';
import PlatformLocationMixin from '~/pkg/pai/mixins/platformLocation';
import { PRODUCT_NAME } from '~/pkg/pai/config/platform';

export default class extends PlatformLocationMixin(SteveModel) {
  get details() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      return [
        {
          label:   this.t('tableHeaders.clusters'),
          content: this.metadata.labels['com.tdology.gateway'],
        },
        {
          label:   'IP',
          content: this.spec.ip,
        },
        {
          label:   this.t('pai.detail.vmset.system'),
          content: this.spec.os,
        },
      ];
    }

    return super.details;
  }
}
