<script>
import { mapGetters } from 'vuex';
import day from 'dayjs';
import { dasherize, ucFirst } from '@shell/utils/string';
import { clone, get } from '@shell/utils/object';
import { removeObject } from '@shell/utils/array';
import { Checkbox } from '@components/Form/Checkbox';
import AsyncButton, { ASYNC_BUTTON_STATES } from '@shell/components/AsyncButton';
import ActionDropdown from '@shell/components/ActionDropdown';
import $ from 'jquery';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import THead from '@shell/components/SortableTable/THead';
import filtering from '@shell/components/SortableTable/filtering';
import selection from '@shell/components/SortableTable/selection';
import sorting from '@shell/components/SortableTable/sorting';
import paging from '@shell/components/SortableTable/paging';
import grouping from '@shell/components/SortableTable/grouping';
import actions from '@shell/components/SortableTable/actions';
import AdvancedFiltering from '@shell/components/SortableTable/advanced-filtering';
import LabeledSelect from '@shell/components/form/LabeledSelect';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { _IMPORT, MODE } from '@/shell/config/query-params';
import { CAPI, MANAGEMENT } from '@/shell/config/types';
import { FORMATTERS } from '@shell/components/SortableTable';
import { PAI_CAPI, PAI_PLATFORM_SETTING_TYPES } from '../../../config/types';
import { PRODUCT_NAME as PLATFORM } from '../../../config/platform';
import PaiPodsUsage from '../../../formatters/PaiPodsUsage.vue';
import PaiClusterProvider from '../../../formatters/PaiClusterProvider.vue';
import PaiClusterName from '../../../formatters/PaiClusterName.vue';
import { CLUSTER_ANNOTATIONS } from '../../../config/labels-annotations';
import { compare } from '@shell/utils/sort';
import { BLANK_CLUSTER } from '@shell/store';
import { _CREATE } from '@shell/config/query-params';
import ClusterCard from './ClusterCard.vue';
import dayjs from 'dayjs';
import { SETTING } from '@shell/config/settings';

export default {
  components: {
    ClusterCard,
    THead,
    Checkbox,
    AsyncButton,
    ActionDropdown,
    LabeledSelect,
    PaiClusterProvider,
    PaiPodsUsage,
    PaiClusterName,
  },
  mixins: [
    filtering,
    sorting,
    paging,
    grouping,
    selection,
    actions,
    AdvancedFiltering,
    // For table performance debugging - uncomment and uncomment the corresponding import
    // tableDebug,
  ],

  props: {
    headers: {
      type:     Array,
      required: true,
    },
    rows: {
      // The array of objects to show
      type:     Array,
      required: true,
    },
    keyField: {
      // Field that is unique for each row.
      type:    String,
      default: '_key',
    },

    loading: {
      type:     Boolean,
      required: false,
    },

    groupBy: {
      // Field to group rows by, row[groupBy] must be something that can be a map key
      type:    String,
      default: null,
    },
    groupRef: {
      // Object to provide as the reference for rendering the grouping row
      type:    String,
      default: null,
    },

    defaultSortBy: {
      // Default field to sort by if none is specified
      // uses name on headers
      type:    String,
      default: null,
    },

    rowActions: {
      // Show action dropdown on the end of each row
      type:    Boolean,
      default: false,
    },

    tableActions: {
      // Show bulk table actions
      type:    Boolean,
      default: true,
    },

    mangleActionResources: {
      type:    Function,
      default: null,
    },

    rowActionsWidth: {
      // How wide the action dropdown column should be
      type:    Number,
      default: 40,
    },

    search: {
      // Show search input to filter rows
      type:    Boolean,
      default: true,
    },

    extraSearchFields: {
      // Additional fields that aren't defined in the headers to search in on each row
      type:    Array,
      default: null,
    },

    subRows: {
      // If there are sub-rows, your main row must have <tr class="main-row"> to identify it
      type:    Boolean,
      default: false,
    },

    subExpandable: {
      type:    Boolean,
      default: false,
    },

    subSearch: {
      // A field containing an array of sub-items to also search in for each row
      type:    String,
      default: null,
    },

    /**
     * Show the divider between the thead and tbody.
     */
    topDivider: {
      type:    Boolean,
      default: true,
    },

    /**
     * Show the dividers between rows
     */
    bodyDividers: {
      type:    Boolean,
      default: false,
    },

    overflowX: {
      type:    Boolean,
      default: false,
    },
    overflowY: {
      type:    Boolean,
      default: false,
    },

    /**
     * If pagination of the data is enabled or not
     */
    paging: {
      type:    Boolean,
      default: false,
    },

    /**
     * What translation key to use for displaying the '1 - 10 of 100 Things' pagination info
     */
    pagingLabel: {
      type:    String,
      default: 'sortableTable.paging.generic',
    },

    /**
     * Additional params to pass to the pagingLabel translation
     */
    pagingParams: {
      type:    Object,
      default: null,
    },

    /**
     * Allows you to override the default preference of the number of
     * items to display per page. This is used by ./paging.js if you're
     * looking for a reference.
     */
    rowsPerPage: {
      type:    Number,
      default: null, // Default comes from the user preference
    },

    /**
     * Allows you to override the default translation text of no rows view
     */
    noRowsKey: {
      type:    String,
      default: 'sortableTable.noRows',
    },

    /**
     * Allows you to hide the no rows messaging.
     */
    showNoRows: {
      type:    Boolean,
      default: true,
    },

    /**
     * Allows you to override the default translation text of no search data view
     */
    noDataKey: {
      type:    String,
      default: 'sortableTable.noData',
    },

    /**
     * Allows you to override showing the THEAD section.
     */
    showHeaders: {
      type:    Boolean,
      default: true,
    },

    sortGenerationFn: {
      type:    Function,
      default: null,
    },

    /**
     * Allows you to link to a custom detail page for data that
     * doesn't have a class model. For example, a receiver configuration
     * block within an AlertmanagerConfig resource.
     */
    getCustomDetailLink: {
      type:    Function,
      default: null,
    },

    /**
     * Inherited global identifier prefix for tests
     * Define a term based on the parent component to avoid conflicts on multiple components
     */
    componentTestid: {
      type:    String,
      default: 'sortable-table',
    },
    /**
     * Allows for the usage of a query param to work for simple filtering (q)
     */
    useQueryParamsForSimpleFiltering: {
      type:    Boolean,
      default: false,
    },
    /**
     * Manaul force the update of live and delayed cells. Change this number to kick off the update
     */
    forceUpdateLiveAndDelayed: {
      type:    Number,
      default: 0,
    },
  },
  data() {
    let searchQuery = '';
    let eventualSearchQuery = '';

    // only allow for filter query param for simple filtering for now...
    if (!this.hasAdvancedFiltering && this.useQueryParamsForSimpleFiltering && this.$route.query?.q) {
      searchQuery = this.$route.query?.q;
      eventualSearchQuery = this.$route.query?.q;
    }

    return {
      currentPhase:     ASYNC_BUTTON_STATES.WAITING,
      expanded:         {},
      searchQuery,
      eventualSearchQuery,
      actionOfInterest: null,
      loadingDelay:     false,
      tabMode:          'card',
      PAI,
      filterValue:      '',
      resultRows:       [],
    };
  },

  mounted() {
    this._loadingDelayTimer = setTimeout(() => {
      this.loadingDelay = true;
    }, 200);

    // Add scroll listener to the main element
    const $main = $('main');

    this._onScroll = this.onScroll.bind(this);
    $main.on('scroll', this._onScroll);
  },

  beforeDestroy() {
    clearTimeout(this.loadingDelayTimer);
    clearTimeout(this._scrollTimer);
    clearTimeout(this._loadingDelayTimer);
    clearTimeout(this._liveColumnsTimer);
    clearTimeout(this._delayedColumnsTimer);
    clearTimeout(this.manualRefreshTimer);

    const $main = $('main');

    $main.off('scroll', this._onScroll);
  },

  watch: {
    eventualSearchQuery: debounce(function(q) {
      this.searchQuery = q;

      if (!this.hasAdvancedFiltering && this.useQueryParamsForSimpleFiltering) {
        const route = {
          name:   this.$route?.name,
          params: { ...this.$route.params },
          query:  {
            ...this.$route.query,
            q,
          },
        };

        if (!q && this.$route.query?.q) {
          route.query = {};
        }

        this.$router.replace(route);
      }
    }, 200),

    descending(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    searchQuery(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    sortFields(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    groupBy(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    namespaces(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    page(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    forceUpdateLiveAndDelayed(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },

    // Ensure we update live and delayed columns on first load
    initalLoad: {
      handler(neu) {
        if (neu) {
          this._didinit = true;
          this.$nextTick(() => this.updateLiveAndDelayed());
        }
      },
      immediate: true,
    },

    // this is the flag that indicates that manual refresh data has been loaded
    // and we should update the deferred cols
    manualRefreshLoadingFinished: {
      handler(neu, old) {
        // this is merely to update the manual refresh button status
        this.currentPhase = !neu ? ASYNC_BUTTON_STATES.WAITING : ASYNC_BUTTON_STATES.ACTION;
        if (neu && neu !== old) {
          this.$nextTick(() => this.updateLiveAndDelayed());
        }
      },
      immediate: true,
    },
    rows: {
      handler(nue) {
        if (nue) {
          this.resultRows = nue.sort((a, b) => {
            return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
          }).sort((a, b) => compare(b.starTimeStamp, a.starTimeStamp));
        }
      },
      deep:      true,
      immediate: true,
    },
  },

  created() {
    this.debouncedRefreshTableData = debounce(this.refreshTableData, 500);
  },

  computed: {
    CLUSTER_ANNOTATIONS() {
      return CLUSTER_ANNOTATIONS;
    },
    ...mapGetters({ isTooManyItemsToAutoUpdate: 'resource-fetch/isTooManyItemsToAutoUpdate' }),
    ...mapGetters({ isManualRefreshLoading: 'resource-fetch/manualRefreshIsLoading' }),
    namespaces() {
      return this.$store.getters['activeNamespaceCache'];
    },

    initalLoad() {
      return !!(!this.loading && !this._didinit && this.rows?.length);
    },

    manualRefreshLoadingFinished() {
      return !!(!this.loading && this._didinit && this.rows?.length && !this.isManualRefreshLoading);
    },

    fullColspan() {
      return 12;
    },

    noResults() {
      return !!this.searchQuery && this.pagedRows.length === 0;
    },

    noRows() {
      return !this.noResults && (this.rows || []).length === 0;
    },

    showHeaderRow() {
      return this.search ||
        this.tableActions ||
        this.$slots['header-left']?.length ||
        this.$slots['header-middle']?.length ||
        this.$slots['header-right']?.length;
    },

    columns() {
      // Filter out any columns that are too heavy to show for large page sizes
      const out = this.headers.slice()
        .filter(c => !c.maxPageSize || (c.maxPageSize && c.maxPageSize >= this.perPage));

      if (this.groupBy) {
        const entry = out.find(x => x?.name === this.groupBy);

        if (entry) {
          removeObject(out, entry);
        }
      }

      // If all columns have a width, try to remove it from a column that can be variable (name)
      const missingWidth = out.find(x => !x.width);

      if (!missingWidth) {
        const variable = out.find(x => x.canBeVariable);

        if (variable) {
          const neu = clone(variable);

          delete neu.width;

          out.splice(out.indexOf(variable), 1, neu);
        }
      }

      // handle cols visibility and filtering if there is advanced filtering
      if (this.hasAdvancedFiltering) {
        const cols = this.handleColsVisibilyAndFiltering(out);

        return cols;
      }

      return out;
    },

    // For data-title properties on <td>s
    dt() {
      const out = {
        check:   `Select: `,
        actions: `Actions: `,
      };

      this.columns.forEach((col) => {
        out[col.name] = `${ (col.label || col?.name) }:`;
      });

      return out;
    },

    classObject() {
      return {
        'top-divider':   this.topDivider,
        'body-dividers': this.bodyDividers,
        'overflow-y':    this.overflowY,
        'overflow-x':    this.overflowX,
      };
    },

    // Do we have any live columns?
    hasLiveColumns() {
      const liveColumns = this.columns.find(c => c.formatter?.startsWith('Live') || c.liveUpdates);

      return !!liveColumns;
    },

    hasDelayedColumns() {
      const delaeydColumns = this.columns.find(c => c.delayLoading);

      return !!delaeydColumns;
    },

    columnFormmatterIDs() {
      const columnsIds = {};

      this.columns.forEach((c) => {
        if (c.formatter) {
          columnsIds[c.formatter] = dasherize(c.formatter);
        }
      });

      return columnsIds;
    },

    // Generate row and column data for easier rendering in the template
    // ensures we only call methods like `valueFor` once
    displayRows() {
      const rows = [];
      const columnFormmatterIDs = this.columnFormmatterIDs;

      this.groupedRows.forEach((grp) => {
        const group = {
          grp,
          key:  grp.key,
          ref:  grp.ref,
          rows: [],
        };

        rows.push(group);

        grp.rows.forEach((row) => {
          const rowData = {
            row,
            key:                        this.get(row, this.keyField),
            showSubRow:                 this.showSubRow(row, this.keyField),
            canRunBulkActionOfInterest: this.canRunBulkActionOfInterest(row),
            columns:                    [],
          };

          group.rows.push(rowData);

          this.columns.forEach((c) => {
            const value = c.delayLoading ? undefined : this.valueFor(row, c, c.isLabel);
            let component;
            let formatted = value;
            let needRef = false;

            if (Array.isArray(value)) {
              formatted = value.join(', ');
            }
            if (c.formatter) {
              if (FORMATTERS[c.formatter]) {
                component = FORMATTERS[c.formatter];
                needRef = true;
              } else {
                // Check if we have a formatter from a plugin
                const pluginFormatter = this.$plugin?.getDynamic('formatters', c.formatter);

                if (pluginFormatter) {
                  component = pluginFormatter;
                  needRef = true;
                }
              }
            }

            rowData.columns.push({
              col:       c,
              value,
              formatted,
              component,
              needRef,
              delayed:   c.delayLoading,
              live:      c.formatter?.startsWith('Live') || c.liveUpdates,
              label:     this.labelFor(c),
              dasherize: columnFormmatterIDs[c.formatter] || '',
            });
          });
        });
      });

      return rows;
    },
    canCreateCluster() {
      const schema = this.$store.getters['management/schemaFor'](CAPI.RANCHER_CLUSTER);

      return !!schema?.collectionMethods.find(x => x.toLowerCase() === 'post');
    },
    _createLocation() {
      return {
        name:   `manage-cluster-resource-create`,
        params: {
          cluster:  BLANK_CLUSTER,
          resource: PAI_CAPI.RANCHER_CLUSTER,
        },
        query: {
          [MODE]: _CREATE,
          type:   'custom',
        },
      };
    },
    _isGatewayCreatable() {
      const schema = this.$store.getters['management/schemaFor'](PAI_PLATFORM_SETTING_TYPES.GATEWAY);

      return !!schema?.collectionMethods.find(x => x.toLowerCase() === 'post');
    },
    _createGatewayLocation() {
      return {
        name:   `${ PLATFORM }-c-cluster-resource-create`,
        params: {
          product:  PLATFORM,
          cluster:  BLANK_CLUSTER,
          resource: PAI_PLATFORM_SETTING_TYPES.GATEWAY
        },
      };
    },
    canImport() {
      const schema = this.$store.getters['management/schemaFor'](CAPI.RANCHER_CLUSTER);

      return !!schema?.collectionMethods.find(x => x.toLowerCase() === 'post');
    },
    _createButtonlabel() {
      return this.createButtonLabel || this.t('resourceList.head.create');
    },
  },

  methods: {
    get,
    refreshTableData() {
      this.$store.dispatch('resource-fetch/doManualRefresh');
    },

    dasherize,

    onScroll() {
      if (this.hasLiveColumns || this.hasDelayedColumns) {
        clearTimeout(this._liveColumnsTimer);
        clearTimeout(this._scrollTimer);
        clearTimeout(this._delayedColumnsTimer);
        this._scrollTimer = setTimeout(() => {
          this.updateLiveColumns();
          this.updateDelayedColumns();
        }, 300);
      }
    },

    watcherUpdateLiveAndDelayed(neu, old) {
      if (neu !== old) {
        this.$nextTick(() => this.updateLiveAndDelayed());
      }
    },

    updateLiveAndDelayed() {
      if (this.hasLiveColumns) {
        this.updateLiveColumns();
      }

      if (this.hasDelayedColumns) {
        this.updateDelayedColumns();
      }
    },

    updateDelayedColumns() {
      clearTimeout(this._delayedColumnsTimer);
      if (!this.$refs.column || this.pagedRows.length === 0) {
        return;
      }

      const delayedColumns = this.$refs.column.filter(c => c.startDelayedLoading && !c.__delayedLoading);
      // We add 100 pixels here - so we will render the delayed columns for a few extra rows below what is visible
      // This way if you scroll slowly, you won't see the columns being loaded
      const clientHeight = (window.innerHeight || document.documentElement.clientHeight) + 100;

      let scheduled = 0;

      for (let i = 0; i < delayedColumns.length; i++) {
        const dc = delayedColumns[i];
        const y = dc.$el.getBoundingClientRect().y;

        if (y >= 0 && y <= clientHeight) {
          dc.startDelayedLoading(true);
          dc.__delayedLoading = true;

          scheduled++;

          // Only update 4 at a time
          if (scheduled === 4) {
            this._delayedColumnsTimer = setTimeout(this.updateDelayedColumns, 100);

            return;
          }
        }
      }
    },

    updateLiveColumns() {
      clearTimeout(this._liveColumnsTimer);

      if (!this.$refs.column || !this.hasLiveColumns || this.pagedRows.length === 0) {
        return;
      }

      const clientHeight = window.innerHeight || document.documentElement.clientHeight;
      const liveColumns = this.$refs.column.filter(c => !!c.liveUpdate);
      const now = day();
      let next = Number.MAX_SAFE_INTEGER;

      for (let i = 0; i < liveColumns.length; i++) {
        const column = liveColumns[i];
        const y = column.$el.getBoundingClientRect().y;

        if (y >= 0 && y <= clientHeight) {
          const diff = column.liveUpdate(now);

          if (diff < next) {
            next = diff;
          }
        }
      }

      if (next < 1) {
        next = 1;
      }

      // Schedule again
      this._liveColumnsTimer = setTimeout(() => this.updateLiveColumns(), next * 1000);
    },

    labelFor(col) {
      if (col.labelKey) {
        return this.t(col.labelKey, undefined, true);
      } else if (col.label) {
        return col.label;
      }

      return ucFirst(col?.name);
    },

    valueFor(row, col, isLabel) {
      if (typeof col.value === 'function') {
        return col.value(row);
      }

      if (isLabel) {
        if (row.metadata?.labels && row.metadata?.labels[col.label]) {
          return row.metadata?.labels[col.label];
        }

        return '';
      }

      const expr = col.value || col?.name;
      const out = get(row, expr);

      if (out === null || out === undefined) {
        return '';
      }

      return out;
    },

    setBulkActionOfInterest(action) {
      this.actionOfInterest = action;
    },

    // Can the action of interest be applied to the specified resource?
    canRunBulkActionOfInterest(resource) {
      if (!this.actionOfInterest) {
        return false;
      }

      const matchingResourceAction = resource.availableActions.find(a => a.action === this.actionOfInterest.action);

      return matchingResourceAction?.enabled;
    },

    focusSearch() {
      if (this.$refs.searchQuery) {
        this.$refs.searchQuery.focus();
        this.$refs.searchQuery.select();
      }
    },

    nearestCheckbox() {
      const $cur = $(document.activeElement)
        .closest('tr.main-row')
        .find('.checkbox-custom');

      return $cur[0];
    },

    focusAdjacent(next = true) {
      const all = $('.checkbox-custom', this.$el)
        .toArray();
      const cur = this.nearestCheckbox();
      let idx = -1;

      if (cur) {
        idx = all.indexOf(cur) + (next ? 1 : -1);
      } else if (next) {
        idx = 1;
      } else {
        idx = all.length - 1;
      }

      if (idx < 1) { // Don't go up to the check all button
        idx = 1;
      }

      if (idx >= all.length) {
        idx = all.length - 1;
      }

      if (all[idx]) {
        all[idx].focus();

        return all[idx];
      }
    },

    focusNext: throttle(function(event, more = false) {
      const elem = this.focusAdjacent(true);
      const row = $(elem)
        .parents('tr');

      this.keySelectRow(row, more);
    }, 50),

    focusPrevious: throttle(function(event, more = false) {
      const elem = this.focusAdjacent(false);
      const row = $(elem)
        .parents('tr');

      this.keySelectRow(row, more);
    }, 50),

    showSubRow(row, keyField) {
      const hasInjectedSubRows = this.subRows && (!this.subExpandable || this.expanded[get(row, keyField)]);
      const hasStateDescription = row.stateDescription;

      return hasInjectedSubRows || hasStateDescription;
    },

    handleActionButtonClick(i, event) {
      this.$emit('clickedActionButton', {
        event,
        targetElement: this.$refs[`actionButton${ i }`][0],
      });
    },
    changeCollection() {
      this.resultRows = this.rows.sort((a, b) => {
        return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
      }).sort((a, b) => {
        return compare(b.starTimeStamp, a.starTimeStamp);
      });
    },
    async onImport() {

      if (this.$store.getters['cluster/schemaFor'](MANAGEMENT.SETTING)) {
        const serverUrlSetting = await this.$store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.SERVER_URL);
        if (serverUrlSetting?.value) {
          this.$router.push( {
            name:   `manage-cluster-resource-create`,
            params: {
              cluster:  BLANK_CLUSTER,
              resource: PAI_CAPI.RANCHER_CLUSTER,
            },
            query: {
              [MODE]: _IMPORT,
              type:   _IMPORT,
            },
          });
        } else {
          this.$store.dispatch('cluster/promptModal', {
            component:      'ServerURLDialog',
            componentProps: {
              confirm: () => {
                this.$router.push( {
                  name:   `manage-cluster-resource-create`,
                  params: {
                    cluster:  BLANK_CLUSTER,
                    resource: PAI_CAPI.RANCHER_CLUSTER,
                  },
                  query: {
                    [MODE]: _IMPORT,
                    type:   _IMPORT,
                  },
                });
              }
            }
          });
        }
      }

    },
  },
};
</script>

<template>
  <div ref="container">
    <div
      :class="{'titled': $slots.title && $slots.title.length}"
      class="sortable-table-header"
    >
      <slot name="title" />
      <div
        v-if="showHeaderRow"
        class="fixed-header-actions"
        :class="{button: !!$slots['header-button'], 'advanced-filtering': hasAdvancedFiltering}"
      >
        <div class="bulk">
          <n-link
            v-if="canCreateCluster"
            :to="_createLocation"
            class="btn role-primary"
            :data-testid="componentTestid+'-create-custom'"
          >
            <i class="el-icon-plus" />{{ t('cluster.createCustomAction') }}
          </n-link>
          <n-link
            v-if="_isGatewayCreatable"
            :to="_createGatewayLocation"
            class="btn role-primary"
            :data-testid="componentTestid+'-create-gateway'"
          >
            <i class="el-icon-plus" />{{ t('cluster.createGatewayAction') }}
          </n-link>
          <button
            v-if="canImport"
            type="button"
            class="btn role-primary mr-10"
            @click="onImport"
          >
            <i class="el-icon-upload2" />{{ t('cluster.importAction') }}
          </button>
          <div
            v-show="tabMode === 'list'"
            class="extraButton"
          >
            <slot name="header-left">
              <template>
                <button
                  v-for="act in availableActions"
                  :id="act.action"
                  :key="act.action"
                  v-tooltip="actionTooltip"
                  type="button"
                  class="btn role-primary"
                  :class="{[bulkActionClass]:true}"
                  :disabled="!act.enabled"
                  :data-testid="componentTestid + '-' + act.action"
                  @click="applyTableAction(act, null, $event)"
                  @mouseover="setBulkActionOfInterest(act)"
                  @mouseleave="setBulkActionOfInterest(null)"
                >
                  <i
                    v-if="act.icon"
                    :class="act.icon"
                  />
                  <span v-html="act.label" />
                </button>
                <ActionDropdown
                  :class="bulkActionsDropdownClass"
                  class="bulk-actions-dropdown"
                  :disable-button="!selectedRows.length"
                  size="sm"
                >
                  <template #button-content>
                    <button
                      ref="actionDropDown"
                      class="btn bg-primary mr-0"
                      :disabled="!selectedRows.length"
                    >
                      <i class="icon icon-gear" />
                      <span>{{ t('sortableTable.bulkActions.collapsed.label') }}</span>
                      <i class="ml-10 icon icon-chevron-down" />
                    </button>
                  </template>
                  <template #popover-content>
                    <ul class="list-unstyled menu">
                      <li
                        v-for="act in hiddenActions"
                        :key="act.action"
                        v-close-popover
                        v-tooltip="{
                          content: actionTooltip,
                          placement: 'right'
                        }"
                        :class="{ disabled: !act.enabled }"
                        @click="applyTableAction(act, null, $event)"
                        @mouseover="setBulkActionOfInterest(act)"
                        @mouseleave="setBulkActionOfInterest(null)"
                      >
                        <i
                          v-if="act.icon"
                          :class="act.icon"
                        />
                        <span v-html="act.label" />
                      </li>
                    </ul>
                  </template>
                </ActionDropdown>
                <label
                  v-if="selectedRowsText"
                  :class="bulkActionAvailabilityClass"
                  class="action-availability"
                >
                  {{ selectedRowsText }}
                </label>
              </template>
            </slot>
          </div>
        </div>
        <div class="search row">
          <el-input
            v-model="filterValue"
            :placeholder="t('pai.detail.vmset.filter')"
            prefix-icon="el-icon-search"
          />
          <el-radio-group
            v-model="tabMode"
          >
            <el-radio-button
              label="card"
            >
              {{ t('pai.overview.card') }}
            </el-radio-button>
            <el-radio-button
              label="list"
            >
              {{ t('pai.overview.list') }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <table
      v-show="tabMode==='list'"
      class="sortable-table"
      :class="classObject"
      width="100%"
    >
      <THead
        v-if="showHeaders"
        :label-for="labelFor"
        :columns="columns"
        :group="group"
        :group-options="advGroupOptions"
        :has-advanced-filtering="hasAdvancedFiltering"
        :adv-filter-hide-labels-as-cols="advFilterHideLabelsAsCols"
        :table-actions="tableActions"
        :table-cols-options="columnOptions"
        :row-actions="rowActions"
        :row-actions-width="rowActionsWidth"
        :how-much-selected="howMuchSelected"
        :sort-by="sortBy"
        :default-sort-by="_defaultSortBy"
        :descending="descending"
        :no-rows="noRows"
        :loading="loading && !loadingDelay"
        :no-results="noResults"
        @on-toggle-all="onToggleAll"
        @on-sort-change="changeSort"
        @col-visibility-change="changeColVisibility"
        @group-value-change="(val) => $emit('group-value-change', val)"
        @update-cols-options="updateColsOptions"
      />
      <!-- Don't display anything if we're loading and the delay has yet to pass -->
      <div v-if="loading && !loadingDelay" />
      <tbody v-else-if="loading">
        <slot name="loading">
          <tr>
            <td :colspan="fullColspan">
              <div class="data-loading">
                <i class="icon-spin icon icon-spinner" />
                <t
                  k="generic.loading"
                  :raw="true"
                />
              </div>
            </td>
          </tr>
        </slot>
      </tbody>
      <tbody v-else-if="noRows">
        <slot name="no-rows">
          <tr class="no-rows">
            <td :colspan="fullColspan">
              <t
                v-if="showNoRows"
                :k="noRowsKey"
              />
            </td>
          </tr>
        </slot>
      </tbody>
      <tbody v-else-if="noResults">
        <slot name="no-results">
          <tr class="no-results">
            <td
              :colspan="fullColspan"
              class="text-center"
            >
              <t :k="noDataKey" />
            </td>
          </tr>
        </slot>
      </tbody>
      <tbody
        v-for="groupedRows in displayRows"
        v-else
        :key="groupedRows.key"
        :class="{ group: groupBy }"
      >
        <template
          v-for="(row, i) in groupedRows.rows.filter(v=>v.row.nameDisplay.includes(filterValue) ||v.row.id.includes(filterValue) || v.row.kubernetesVersion.includes(filterValue))"
        >
          <slot
            name="main-row"
            :row="row.row"
          >
            <slot
              :name="'main-row:' + (row.row.mainRowKey || i)"
              :full-colspan="fullColspan"
            >
              <tr
                :key="row.key"
                class="main-row"
                :data-testid="componentTestid + '-' + i + '-row'"
                :class="{ 'has-sub-row': row.showSubRow}"
                :data-node-id="row.key"
                :data-cant-run-bulk-action-of-interest="actionOfInterest && !row.canRunBulkActionOfInterest"
              >
                <td
                  v-if="tableActions"
                  class="row-check"
                  align="middle"
                >
                  {{ row.mainRowKey }}
                  <Checkbox
                    class="selection-checkbox"
                    :data-node-id="row.key"
                    :data-testid="componentTestid + '-' + i + '-checkbox'"
                    :value="selectedRows.includes(row.row)"
                  />
                </td>
                <template v-for="(col,j) in row.columns">
                  <slot
                    :name="'col:' + col.col.name"
                    :row="row.row"
                    :col="col.col"
                    :dt="dt"
                    :expanded="expanded"
                    :rowKey="row.key"
                  >
                    <td
                      v-show="!hasAdvancedFiltering || (hasAdvancedFiltering && col.col.isColVisible)"
                      :key="col.col.name"
                      :data-title="col.col.label"
                      :data-testid="`sortable-cell-${ i }-${ j }`"
                      :align="col.col.align || 'left'"
                      :class="{['col-'+col.dasherize]: !!col.col.formatter, [col.col.breakpoint]: !!col.col.breakpoint, ['skip-select']: col.col.skipSelect}"
                      :width="col.col.width"
                    >
                      <slot
                        :name="'cell:' + col.col.name"
                        :row="row.row"
                        :col="col.col"
                        :value="col.value"
                      >
                        <component
                          :is="col.component"
                          v-if="col.component && col.needRef"
                          ref="column"
                          :value="col.value"
                          :row="row.row"
                          :col="col.col"
                          v-bind="col.col.formatterOpts"
                          :row-key="row.key"
                          :get-custom-detail-link="getCustomDetailLink"
                        />
                        <component
                          :is="col.component"
                          v-else-if="col.component"
                          :value="col.value"
                          :row="row.row"
                          :col="col.col"
                          v-bind="col.col.formatterOpts"
                          :row-key="row.key"
                        />
                        <component
                          :is="col.col.formatter"
                          v-else-if="col.col.formatter"
                          :value="col.value"
                          :row="row.row"
                          :col="col.col"
                          v-bind="col.col.formatterOpts"
                          :row-key="row.key"
                        />
                        <template v-else-if="col.value !== ''">
                          {{ col.formatted }}
                        </template>
                        <template v-else-if="col.col.dashIfEmpty">
                          <span class="text-muted">&mdash;</span>
                        </template>
                      </slot>
                    </td>
                  </slot>
                </template>
              </tr>
            </slot>
          </slot>
          <slot
            v-if="row.showSubRow"
            name="sub-row"
            :full-colspan="fullColspan"
            :row="row.row"
            :sub-matches="subMatches"
            :onRowMouseEnter="onRowMouseEnter"
            :onRowMouseLeave="onRowMouseLeave"
          >
            <tr
              v-if="row.row.stateDescription"
              :key="row.row[keyField] + '-description'"
              :data-testid="componentTestid + '-' + i + '-row-description'"
              class="state-description sub-row"
              @mouseenter="onRowMouseEnter"
              @mouseleave="onRowMouseLeave"
            >
              <td
                :colspan="fullColspan - (tableActions ? 1: 0)"
                :class="{ 'text-error' : row.row.stateObj.error }"
              >
                {{ row.row.stateDescription }}
              </td>
            </tr>
          </slot>
        </template>
      </tbody>
    </table>
    <div
      v-if="showPaging"
      class="paging"
    >
      <button
        type="button"
        class="btn btn-sm role-multi-action"
        :disabled="page == 1"
        @click="goToPage('first')"
      >
        <i class="icon icon-chevron-beginning" />
      </button>
      <button
        type="button"
        class="btn btn-sm role-multi-action"
        :disabled="page == 1"
        @click="goToPage('prev')"
      >
        <i class="icon icon-chevron-left" />
      </button>
      <span>
        {{ pagingDisplay }}
      </span>
      <button
        type="button"
        class="btn btn-sm role-multi-action"
        :disabled="page == totalPages"
        @click="goToPage('next')"
      >
        <i class="icon icon-chevron-right" />
      </button>
      <button
        type="button"
        class="btn btn-sm role-multi-action"
        :disabled="page == totalPages"
        @click="goToPage('last')"
      >
        <i class="icon icon-chevron-end" />
      </button>
    </div>
    <div v-show="tabMode==='card'">
      <div class="cards">
        <cluster-card
          v-for="cluster in resultRows.filter(v=>v.nameDisplay.includes(filterValue) ||v.id.includes(filterValue) || v.kubernetesVersion.includes(filterValue))"
          :key="cluster.id"
          :cluster="cluster"
          @changeCollection="changeCollection"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.fixed-header-actions {
  grid-template-columns: [bulk] auto [middle] min-content [search] minmax(min-content, 400px);

  i {
    margin-right: 10px;
  }
  .bulk {
    & > BUTTON {
      display: inline-block;
    }
  }
}

.el-input {
  width: 200px;
  margin-right: 10px;
}

.extraButton {
  display: inline-block;

  button {
    margin-right: 10px;
  }
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: var(--primary);
}
</style>
