<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import { set } from '@shell/utils/object';
import { LabeledInput } from '@components/Form/LabeledInput';
import CruResource from '@shell/components/CruResource';
import { _CREATE, _EDIT, _VIEW } from '@shell/config/query-params';
import { mapGetters } from 'vuex';
import LabeledRadioGroup from '@/pkg/pai/components/form/LabeledRadioGroup';

export default {
  components: {
    CruResource,
    LabeledInput,
    LabeledRadioGroup,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: _EDIT
    },
    realMode: {
      type:    String,
      default: _EDIT
    }
  },
  data() {
    // Add fields missing when creating new
    const obj = {
      issuer: '', user: '', limits: { cpu: '', memory: '' }, realtime: false
    };

    if (!this.value.spec) {
      this.$set(this.value, 'spec', obj);
    }
    const valid = `${ this.value.spec.realtime }`;

    return { valid };
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    _CREATE() {
      return _CREATE;
    },
    _EDIT() {
      return _EDIT;
    },
    _VIEW() {
      return _VIEW;
    }
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    async save(saveCb) {
      try {
        await this.value.save();
        this.$message.success(this.t('pai.edit.tips.success'));
        saveCb(true);
        this.done();
      } catch (e) {
        saveCb(false);
        this.$message.error(e);
      }
    },
    onValidChanged(e) {
      if (e === 'true') {
        set(this.value, 'spec.realtime', true);
      } else {
        set(this.value, 'spec.realtime', false);
      }
    }
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="save"
    @cancel="done"
  >
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.metadata.name"
        type="text"
        :required="true"
        :label="t('pai.edit.license.name')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.license.name')"
        :mode="(realMode===_EDIT||realMode===_VIEW) ? _VIEW : _EDIT"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.issuer"
        type="text"
        :required="true"
        :label="t('pai.edit.license.issuer')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.license.issuer')"
        :mode="mode"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.user"
        type="text"
        :required="true"
        :label="t('pai.edit.license.user')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.license.user')"
        :mode="mode"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.limits.cpu"
        type="text"
        :required="true"
        :label="t('pai.edit.license.cpu')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.license.cpu')"
        :mode="mode"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.limits.memory"
        type="text"
        :required="true"
        :label="t('pai.edit.license.memory')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.license.memory')"
        :mode="mode"
      />
    </div>
    <div class="detail mb-20">
      <LabeledRadioGroup
        v-model.trim="valid"
        name="valid"
        :label="t('pai.edit.license.realtime')"
        :options="[{label:t('generic.yes'),value:'true'},{label:t('generic.no'),value:'false'}]"
        :mode="mode"
        :row="true"
        @input="onValidChanged"
      />
    </div>
  </CruResource>
</template>
