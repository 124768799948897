export function convertCpuToCore(size) {
  if (!size || size === '0') {
    return 0;
  }
  if (typeof size === 'number') {
    return size;
  }
  const valueReg = new RegExp('\\d*');
  const unitReg = new RegExp(`[a-zA-Z]{1,2}`);
  const value = size?.match(valueReg) ? Number(size.match(valueReg)[0]) : 0;
  const unit = size?.match(unitReg) ? size.match(unitReg)[0] : '';

  switch (unit) {
  case '':
    return value;
  case 'n':
    return value / 1000000000;
  case 'm':
    return numDelivery(value / 1000);
  case 'k':
  case 'K':
  case 'ki':
  case 'Ki':
    return value * 1000;
  case 'M':
  case 'mi':
  case 'Mi':
    return value * 1000 * 1000;
  case 'g':
  case 'G':
  case 'gi':
  case 'Gi':
    return value * 1000 * 1000 * 1000;
  case 't':
  case 'T':
  case 'ti':
  case 'Ti':
    return value * 1000 * 1000 * 1000 * 1000;
  default:
    return 0;
  }
}

export function convertUnitToG(size) {
  if (!size || typeof size !== 'string' || size === '0') {
    return `${ 0 }`;
  }
  const valueReg = new RegExp('\\d*\\.\\d*|\\d*');
  const unitReg = new RegExp(`[a-zA-Z]{1,2}`);
  let value = size?.match(valueReg) ? size.match(valueReg)[0] : 0;
  const unit = size?.match(unitReg) ? size.match(unitReg)[0] : '';

  value = Number(value) ? Number(value) : 0;
  switch (unit) {
  case 'b':
  case 'bi':
  case 'bit':
    return numDelivery(value / 1024 / 1024 / 1024);
  case 'k':
  case 'K':
  case 'ki':
  case 'Ki':
    return numDelivery(value / 1024 / 1024);
  case 'm':
    return numDelivery(value / 1024 / 1024 / 1024 / 1024, 1);
  case 'M':
  case 'mi':
  case 'Mi':
    return numDelivery(value / 1024);
  case 'MB':
    return numDelivery(value / 1024 * 1.073741824);
  case 'g':
  case 'G':
  case 'gi':
  case 'Gi':
    return numDelivery(value);
  case 'GB':
    return numDelivery(value * 1.073741824);
  case 't':
  case 'T':
  case 'ti':
  case 'Ti':
    return value * 1024;
  case 'TB':
    return numDelivery(value * 1024 * 1.073741824);
  case 'p':
  case 'P':
    return value * 1024 * 1024;
  case 'e':
  case 'E':
    return value * 1024 * 1024 * 1024;
  default:
    return 0;
  }
}

// 小数数位大于2，则保留两位小数
export function numDelivery(num, digit=2) {
  let result = parseFloat(num);
  let tmplNumber = 10

  for(let i = 1; i< digit; i++) {
    tmplNumber = tmplNumber * 10
  }
  if (isNaN(result)) {
    return 0;
  }
  result = Math.round(num * tmplNumber) / tmplNumber;

  return result;
}

export function getLastDayHour(d, s) { // 近24小时
  const result = [];

  result[0] = `${ d.getMonth() + 1 }/${ d.getDate() } ${ d.getHours() < 10 ? `0${ d.getHours() }` : d.getHours() }:${ d.getMinutes() < 10 ? `0${ d.getMinutes() }` : d.getMinutes() }`;

  d.setHours(d.getHours());// 获取到当前小时
  for (let i = 0; i < s; i++) {
    d.setHours(d.getHours() - 1);
    const time = `${ d.getMonth() + 1 }/${ d.getDate() } ${ d.getHours() < 10 ? `0${ d.getHours() }` : d.getHours() }:${ d.getMinutes() < 10 ? `0${ d.getMinutes() }` : d.getMinutes() }`;

    result.push(time);
  }

  return result.reverse();
}
export function getLastHour(d, s) { // 近1小时
  const result = [];

  result[0] = `${ d.getHours() < 10 ? `0${ d.getHours() }` : d.getHours() }:${ d.getMinutes() < 10 ? `0${ d.getMinutes() }` : d.getMinutes() }`;

  d.setMinutes(d.getMinutes());// 获取到当前分钟
  for (let i = 0; i < s; i++) {
    d.setMinutes(d.getMinutes() - 5);
    const time = `${ d.getHours() < 10 ? `0${ d.getHours() }` : d.getHours() }:${ d.getMinutes() < 10 ? `0${ d.getMinutes() }` : d.getMinutes() }`;

    result.push(time);
  }

  return result.reverse();
}

export function supplementZero(Xdata, data) {
  let n = 0;
  const yAxis = [];
  const xAxis = Xdata;

  for (let i = 0; i < xAxis.length; i++) {
    if (xAxis[i] === data[i]?.[0]) {
      yAxis.push(data[i]?.[1]);
    } else if (xAxis[i] === data[i - n]?.[0]) {
      yAxis.push(data[i - n]?.[1]);
    } else { // 没有对应日期，补零
      yAxis.push(0);

      n += 1;
    }
  }

  return yAxis;
}
