<script>
import CreateEditView from '@/shell/mixins/create-edit-view';
import FormValidation from '@/shell/mixins/form-validation';
import { NETWORK_ATTACHMENT, MANAGEMENT, POD } from '@/shell/config/types';
import { WORKLOAD_TYPES, CATALOG } from '@shell/config/types';
import WorkLoadMixin from './mixins/workload';
import Tabbed from '../../components/Tabbed/index.vue';
import Tab from '../../components/Tabbed/Tab.vue';
import { ADDRESS_ASSIGNMENT_TYPE } from '@/pkg/pai/config/settings';
import { addOffset } from '@/pkg/pai/utils/ip';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { mapGetters, mapState } from 'vuex';
import podEditForm from './podEditForm/index';
import LabeledRadioGroup from '@/pkg/pai/components/form/LabeledRadioGroup';
import { set, get } from '@shell/utils/object';
import { REALTIME } from '../../config/labels-annotations';
import { PAI_CATALOG, PAI_PLATFORM_SETTING_TYPES } from '../../config/types';
import { RESTART_POLICY_TYPES } from '../../config/settings';

const defaultPriorityClassName = 'system-cluster-critical';
const defalutTolerations = {
  effect:            'NoExecute',
  key:               'node.kubernetes.io/unreachable',
  operator:          'Exists',
  tolerationSeconds: 1
};

export default {
  components: {
    Tabbed,
    Tab,
    podEditForm,
    LabeledRadioGroup
  },
  name:   'Workload',
  mixins: [CreateEditView, FormValidation, WorkLoadMixin], // The order here is important since WorkLoadMixin contains some FormValidation configuration
  props:  {
    value: {
      type:     Object,
      required: true,
    },

    mode: {
      type:    String,
      default: 'create',
    },
  },
  async created() {
    if (this.$store.getters['cluster/schemaFor'](NETWORK_ATTACHMENT)) {
      this.networks = await this.$store.dispatch('cluster/findAll', { type: NETWORK_ATTACHMENT });
    }
    const networkData = [this.defaultNetwork];
    let networkSchema = null;

    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.NAD)) {
      networkSchema = this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.NAD);
    }
    if (!networkSchema) {
      this.networkVisible = false;
    } else {
      this.networkVisible = true;
      if (this.type === 'pod') {
        this.value?.metadata?.annotations?.['k8s.v1.cni.cncf.io/networks']?.split(',').forEach((v) => {
          const network = this.networks.find(network => network.metadata.namespace === this.value.metadata.namespace && network.metadata.name === v);

          if (network) {
            const config = JSON.parse(network.spec.config);

            const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
            let ip = '-';
            let srcIP = '-';

            if (this.value.metadata?.annotations && !this.value.metadata?.annotations['k8s.v1.cni.cncf.io/network-status']) {
              this.value.metadata = { annotations: { 'k8s.v1.cni.cncf.io/network-status': '[]' } };
            }
            JSON.parse(this.value.metadata?.annotations['k8s.v1.cni.cncf.io/network-status'])?.forEach((ips) => {
              if (ips.name.includes(network.metadata.name)) {
                if (ips?.ips) {
                  ip = ips?.ips[0];
                }
                if (config?.ipam?.addresses) {
                  srcIP = config?.ipam?.addresses[0].address;
                }
              }
            });
            if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
              if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
                ip = config?.ipam?.ranges[0][0].subnet;
              }
            }
            networkData.push({
              name:        network.metadata.name,
              alias:       network.alias,
              networkType: config.type,
              hostNetwork: config.uplink,
              assignmentType,
              ip,
              srcIP,
            });
          }
        });
      } else {
        const annotationsData = this.type === 'batch.cronjob' ? this.value?.spec?.jobTemplate?.spec?.template?.metadata?.annotations?.['k8s.v1.cni.cncf.io/networks'] : this.value?.spec?.template?.metadata?.annotations?.['k8s.v1.cni.cncf.io/networks'];

        annotationsData?.split(',').forEach((v) => {
          const network = this.networks.find(network => network.metadata.namespace === this.value.metadata.namespace && network.metadata.name === v);

          if (network) {
            const config = JSON.parse(network.spec.config);

            const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
            let ip = '-';
            let srcIP = '-';

            if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
              if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
                ip = config?.ipam?.ranges[0][0].subnet;
              }
            } else if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
              if (config?.ipam?.addresses?.length && config?.ipam?.addresses[0]) {
                ip = addOffset(config?.ipam?.addresses[0].address, this.ipOffset ? Number(this.ipOffset) : 0);
                srcIP = config?.ipam?.addresses[0].address;
              }
            }
            networkData.push({
              name:        network.metadata.name,
              alias:       network.alias,
              networkType: config.type,
              hostNetwork: config.uplink,
              assignmentType,
              ip,
              srcIP,
            });
          }
        });
      }

      this.networkData = networkData;
      this.updateNetworkOptions();
    }
    try {
      let url = '';

      if (this.$store.getters['isRancher']) {
        url = '/api/v1/namespaces/picloud-system/services/http:regui:80/proxy/list';
      } else {
        url = '/api/v1/namespaces/kube-system/services/http:regui:80/proxy/ui/list';
      }
      const schemas = this.$store.getters['cluster/schemaFor'](POD);
      const opt = {
        method:  'get',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        url,
      };
      const data = await schemas.$ctx.dispatch('request', { opt });

      const arr = [];

      for (const key in data) {
        for (const key1 in data[key]) {
          data[key][key1].forEach((item) => {
            arr.push(`${ key }/${ key1 }:${ item }`);
          });
        }
      }
      this.allImages = arr;
    } catch (e) {

    }
    this.handleDuplicateName(this.value.type);

    // 是否有权创建实时容器
    const license = this.networks = await this.$store.dispatch('cluster/findAll', { type: PAI_PLATFORM_SETTING_TYPES.LICENSE });
    let isInstallAgent = false;
    const isRancher = await this.$store.getters['isRancher'];

    if (isRancher) {
      const charts = await this.$store.dispatch('cluster/findAll', { type: CATALOG.APP });

      if (!!charts.find(v => v.name === 'pai-agent')) {
        isInstallAgent = true;
      }
    } else {
      const charts = await this.$store.dispatch('cluster/findAll', { type: PAI_CATALOG.HELM_CHART });

      if (!!charts.find(v => v.name === 'pai-agent')) {
        isInstallAgent = true;
      }
    }
    if (!!license.find(v => v.spec.realtime && v.status.valid) && isInstallAgent) {
      this.isRealtimeValid = true;
    }
  },
  data() {
    let rcs = [];

    if (this.value.type === POD) {
      if (this.value.annotations && this.value.annotations[REALTIME] && this.value.annotations[REALTIME].length) {
        rcs = JSON.parse(this.value.annotations[REALTIME]).rcs;
      }
    } else if (this.value.type === WORKLOAD_TYPES.CRON_JOB) {
      if (this.value?.spec?.jobTemplate?.spec?.template?.metadata?.annotations && this.value?.spec?.jobTemplate?.spec?.template?.metadata?.annotations[REALTIME]?.length) {
        rcs = JSON.parse(this.value?.spec?.jobTemplate?.spec?.template?.metadata?.annotations[REALTIME]).rcs;
      }
    } else if (this.value?.spec?.template?.metadata?.annotations && this.value?.spec?.template?.metadata?.annotations[REALTIME]?.length) {
      rcs = JSON.parse(this.value?.spec?.template?.metadata?.annotations[REALTIME]).rcs;
    }

    return {
      selectedName:         null,
      activeContainerIndex: 0,
      networkData:          [],
      ipOffset:             this.mode !== 'create' ? this.value?.metadata?.name.includes('.') ? this.value?.metadata?.name?.split('.')[this.value?.metadata?.name?.split('.').length - 1] : '' : '',
      aliasArr:             [],
      networks:             [],
      networkOptions:       [],
      allImages:            [],
      radio:                '1',
      loadData:             [],
      networkVisible:       true,
      rcs,
      isRealtimeValid:      false,
    };
  },
  computed: {
    ...mapState(['isRancher']),
    ...mapGetters({ allCharts: 'catalog/charts' }),
    restartPolicyOptions() {
      return RESTART_POLICY_TYPES.map((v) => {
        return {
          value: v,
          label: this.t(`pai.edit.workload.restartPolicy.${ v }`)
        };
      });
    },
    defaultNetwork() {
      return {
        alias:     this.t('pai.vmset.defaultNetwork'),
        isDefault: true,
      };
    },
    isNetworkEmpty() {
      return this.networkData?.length <= 1 && this.networkData[0]?.isDefault === true;
    },
    namespace() {
      return this.value?.metadata?.namespace || '';
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
    chartResource() {
      const chart = this.allCharts.filter((item) => {
        return item?.chartName === 'multus';
      });

      return chart;
    },
    isPodEdit() {
      return this.mode === 'edit' && this.type === 'pod';
    },
    isRealtime() {
      // 如果rcs记录了该容器且用户没有修改容器类型为普通容器，则这个容器是实时容器
      const index = this.rcs.map(v => v.name).indexOf(this.allContainers[this.activeContainerIndex].name);

      return index > -1 && !this.rcs[index].disabled;
    },
  },
  watch: {
    namespace() {
      this.updateNetworkOptions();
    },
    ipOffset(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setRestartState();

        this.networkData = this.networkData.map((item) => {
          let ip = '';

          if (item?.assignmentType === 'static' && item?.ip) {
            ip = addOffset(item?.srcIP, newValue);
          } else {
            ip = item?.ip;
          }

          return {
            ...item,
            ip
          };
        });
      }
    },
    rcs: {
      handler(nue) {
        if (!nue.length) {
          this.addRc();
        }
        const realtime = nue.filter(v => !v.disabled).map((obj) => {
          const newObj = JSON.parse(JSON.stringify(obj));

          delete newObj.disabled;

          return newObj;
        });

        if (realtime.length) {
          /* 设置实时容器参数。设置默认的容忍、优先级 */
          const realtimeStr = JSON.stringify({ rcs: realtime });

          if (this.value.type === POD) {
            this.value.setAnnotation(REALTIME, realtimeStr);
          } else if (this.value.type === WORKLOAD_TYPES.CRON_JOB) {
            if (get(this.value, 'spec.jobTemplate.spec.template.metadata.annotations')) {
              this.value.spec.jobTemplate.spec.template.metadata.annotations[REALTIME] = realtimeStr;
            } else {
              set(this.value, 'spec.jobTemplate.spec.template.metadata.annotations', { [REALTIME]: realtimeStr });
            }
          } else if (get(this.value, 'spec.template.metadata.annotations')) {
            this.value.spec.template.metadata.annotations[REALTIME] = realtimeStr;
          } else {
            set(this.value, 'spec.template.metadata.annotations', { [REALTIME]: realtimeStr });
          }
          if (!this.podTemplateSpec?.tolerations?.length) {
            set(this.podTemplateSpec, 'tolerations', []);
            this.podTemplateSpec.tolerations.push(defalutTolerations);
          }
          if (!this.podTemplateSpec?.priorityClassName) {
            set(this.podTemplateSpec, 'priorityClassName', defaultPriorityClassName);
          }
          this.updateInitContainer(false, this.allContainers[this.activeContainerIndex]);
        } else {
          if (this.value.metadata.annotations && this.value.metadata.annotations[REALTIME]) {
            this.$delete(this.value.metadata.annotations, REALTIME);
          }
          if (get(this.value, 'spec.jobTemplate.spec.template.metadata.annotations')?.[REALTIME]) {
            this.$delete(this.value.spec.jobTemplate.spec.template.metadata.annotations, REALTIME);
          }
          if (get(this.value, 'spec.template.metadata.annotations')?.[REALTIME]) {
            this.$delete(this.value.spec.template.metadata.annotations, REALTIME);
          }
        }
      },
      deep:      true,
      immediate: true,
    },
  },
  methods: {
    changed(tab) {
      const key = this.idKey;

      this.selectedName = tab.selectedName;
      const container = this.containerOptions.find( c => c[key] === tab.selectedName);

      if ( container ) {
        this.selectContainer(container);
      }
      if (tab.selectedName === 'network' && !this.networkVisible) {
        this.$message({
          type:    'warning',
          message: `${ this.t('pai.vmset.tips.netWorkTips') }`,
        });
      }
    },

    /**
     * Find error exceptions to be mapped for each case
     */
    mapError(error) {
      switch (true) {
      case error.includes('violates PodSecurity'): {
        const match = error.match(/\"(.*?)\"/gi);
        const name = match[0];
        const policy = match[1];

        return {
          message: `Pod ${ name } Security Policy Violation ${ policy }`,
          icon:    'icon-pod_security'
        };
      }

      default:
        break;
      }
    },

    /**
     * Map all the error texts to a message and icon object
     */
    getErrorsMap(errors) {
      return !errors || !Array.isArray(errors) ? {} : errors.reduce((acc, error) => ({
        ...acc,
        [error]: this.mapError(error) || {
          message: error,
          icon:    null
        }
      }), {});
    },
    onActiveContainer(i) {
      this.activeContainerIndex = i;
    },
    onClose(tab, i) {
      this.removeContainer(tab);
      this.onActiveContainer(0);
      this.rcs.splice(i, 1);
    },
    async onAdd() {
      await this.addContainerBtn();
      this.activeContainerIndex = await this.allContainers.length - 1;
      await this.addRc();
    },
    getAliasArr() {
      const newArr = [];

      this.networkData?.forEach((item) => {
        newArr.push(item.alias);
      });
      this.aliasArr = [...new Set(newArr)];
    },
    addNetwork() {
      this.setRestartState();
      this.networkData.push({});
      this.getAliasArr();
    },
    removeAllNetworks() {
      this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      }).then(() => {
        this.setRestartState();
        this.networkData.splice(0, this.networkData.length);
        this.networkData.push(this.defaultNetwork);
        this.getAliasArr();
      }).catch(() => {});
    },
    onNetworkChange(e, index) {
      this.setRestartState();
      const config = JSON.parse(e.spec.config);
      const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
      let ip = '-';

      if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
        if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
          ip = config?.ipam?.ranges[0][0].subnet;
        }
      } else if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
        if (config?.ipam?.addresses?.length) {
          ip = config?.ipam?.addresses[0].address;
        }
      }
      this.$set(this.networkData, index, {
        name:        e.metadata.name,
        alias:       e.alias,
        networkType: config.type,
        hostNetwork: config.uplink,
        assignmentType,
        ip,
        srcIP:       ip,
      });
      this.networkData = this.networkData.map((item) => {
        let ip = '';

        if (item?.assignmentType === 'static' && item?.ip) {
          ip = addOffset(item?.srcIP, this.ipOffset ? this.ipOffset : 0);
        } else {
          ip = item?.ip;
        }

        return {
          ...item,
          ip
        };
      });
      this.getAliasArr();
    },
    removeNetwork(row, index) {
      if (row.alias) {
        this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        }).then(() => {
          this.setRestartState();
          this.networkData.splice(index, 1);
          this.getAliasArr();
        });
      } else {
        this.setRestartState();
        this.networkData.splice(index, 1);
        this.getAliasArr();
      }
    },
    updateNetworkOptions() {
      this.networkOptions = this.networks.filter(v => v.metadata.namespace === this.value.metadata.namespace).map((v) => {
        return {
          label: v.alias,
          value: v,
        };
      });
    },
    setRestartState() {
      if (!this.isCreate) {
        this.needRestart = true;
      }
    },
    handleSetIpOffset(e) {
      if (e.target._value) {
        this.ipOffset = Number(e.target._value);
      }
    },
    async handleDuplicateName(value) {
      this.loadData = await this.$store.dispatch('cluster/findAll', { type: value });
    },
    async saveOverride(buttonCb) {
      if (this.mode === 'create') {
        for (const item of this.loadData) {
          if (item.metadata.namespace === this.value.metadata.namespace && item.metadata.name === this.value.metadata.name) {
            this.$message.error(this.t('pai.detail.vmset.duplicateNames'));
            buttonCb(false);

            return;
          }
        }
        this.$set(this.value.metadata, 'name', this.ipOffset ? `${ this.value.metadata.name }.${ Number(this.ipOffset) }` : this.value.metadata.name);
      }
      for (let i = 0; i < this.allContainers.length;i++) {
        if (!this.allContainers[i].image) {
          this.$message.error(this.t('pai.edit.tips.required'));
          buttonCb(false);

          return;
        }
        if (/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g.test(this.allContainers[i].image)) {
          this.$message.error(this.t('pai.apps.container.containerMirroring') + this.t('pai.edit.tips.prohibitChinese'));
          buttonCb(false);

          return;
        }
      }
      const networkDataAlias = this.networkData.filter((v, index) => {
        return index !== 0;
      }).map((item) => {
        return item.name;
      });

      if (this.type === 'pod') {
        this.value.setAnnotation('k8s.v1.cni.cncf.io/networks', networkDataAlias.toString());
      } else if (this.type === 'batch.cronjob') {
        if (get(this.value, 'spec.jobTemplate.spec.template.metadata.annotations')) {
          this.value.spec.jobTemplate.spec.template.metadata.annotations['k8s.v1.cni.cncf.io/networks'] = networkDataAlias.toString();
        } else {
          set(this.value, 'spec.jobTemplate.spec.template.metadata.annotations', { 'k8s.v1.cni.cncf.io/networks': networkDataAlias.toString() });
        }
      } else if (get(this.value, 'spec.template.metadata.annotations')) {
        this.value.spec.template.metadata.annotations['k8s.v1.cni.cncf.io/networks'] = networkDataAlias.toString();
      } else {
        set(this.value, 'spec.template.metadata.annotations', { 'k8s.v1.cni.cncf.io/networks': networkDataAlias.toString() });
      }
      // 当节点调度选择'在匹配调度规则的节点上运行 Pod'，提示添加节点选择器
      if (this.value.spec.template?.spec?.affinity?.nodeAffinity?.requiredDuringSchedulingIgnoredDuringExecution) {
        const { nodeSelectorTerms } = this.value.spec.template?.spec?.affinity?.nodeAffinity?.requiredDuringSchedulingIgnoredDuringExecution;

        if (!nodeSelectorTerms || nodeSelectorTerms.length === 0) {
          this.$message.error(this.t('pai.edit.tips.requiredNodeSelectorTerms'));
          buttonCb(false);

          return;
        }
      }

      try {
        await this.save(buttonCb);
        await this.$store.dispatch('pai-common/updateState', { currentModal: '' });
      } catch (e) {
        buttonCb(false);
        this.$message.error(e);
      }
    },
    doneOverride() {
      this.cancel();
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    handleSwitchImage() {
      this.$set(this.allContainers[this.activeContainerIndex], 'image', '');
    },
    createNadLocation() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          cluster:  this.currentCluster,
          product:  PAI,
          resource: PAI_RESOURCES.NAD,
        },
      });
    },
    handleMultus() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-apps-charts-chart`,
        params: {
          cluster: this.$route.params.cluster,
          product: PAI,
        },
        query: {
          'repo-type': this.chartResource[0]?.repoType, repo: this.chartResource[0]?.repoName, chart: this.chartResource[0]?.chartName
        }
      });
    },
    onChangeType(e) {
      const index = this.rcs.map(v => v.name).indexOf(this.allContainers[this.activeContainerIndex].name);

      if (index > -1) {
        this.$set(this.rcs, index, { ...this.rcs[index], disabled: e === 'false' });
        // 实时容器默认开启特权模式
        if (e === 'true') {
          if (this.allContainers[this.activeContainerIndex].securityContext) {
            this.$set(this.allContainers[this.activeContainerIndex].securityContext, 'privileged', true);
          } else {
            this.$set(this.allContainers[this.activeContainerIndex], 'securityContext', { privileged: true });
          }
        }
      }
    },
    addRc() {
      this.rcs.push({
        name:     this.allContainers[this.activeContainerIndex].name,
        params:   {},
        disabled: true
      });
    },
    async changeHash(hash) {
      window.location.hash = await hash;
    },
    async toAdvanced() {
      await this.changeHash('advanced');
      await setTimeout(async() => {
        await this.changeHash('resources');
      }, 100);
    },
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <form
    v-else
    class="filled-height"
  >
    <CruResource
      :cancel-event="true"
      :validation-passed="fvFormIsValid"
      :selected-subtype="type"
      :resource="value"
      :mode="mode"
      :errors="fvUnreportedValidationErrors"
      :done-route="doneRoute"
      :subtypes="workloadSubTypes"
      :apply-hooks="applyHooks"
      :value="value"
      :errors-map="getErrorsMap(fvUnreportedValidationErrors)"
      @finish="saveOverride"
      @cancel="doneOverride"
      @select-type="selectType"
      @error="e=>errors = e"
    >
      <!-- <pre>{{ JSON.stringify(allContainers, null, 2) }}</pre> -->
      <NameNsDescription
        :value="value"
        :mode="mode"
        :rules="{name: fvGetAndReportPathRules('metadata.name'), namespace: fvGetAndReportPathRules('metadata.namespace'), description: []}"
        @change="name=value.metadata.name"
        @isNamespaceNew="isNamespaceNew = $event"
      />
      <div
        v-if="isCronJob || isReplicable || isStatefulSet || containerOptions.length > 1"
        class="row mb-20"
      >
        <div
          v-if="isCronJob"
          class="col span-3"
        >
          <LabeledInput
            v-model="spec.schedule"
            type="cron"
            required
            :mode="mode"
            :label="t('workload.cronSchedule')"
            :rules="fvGetAndReportPathRules('spec.schedule')"
            placeholder="0 * * * *"
          />
        </div>
        <div
          v-if="isReplicable"
          class="col span-3"
        >
          <LabeledInput
            v-model.number="spec.replicas"
            type="number"
            min="0"
            required
            :mode="mode"
            :label="t('workload.replicas')"
          />
        </div>
        <div
          v-if="isStatefulSet"
          class="col span-3"
        >
          <LabeledSelect
            v-model="spec.serviceName"
            option-label="metadata.name"
            :reduce="service=>service.metadata.name"
            :mode="mode"
            :label="t('workload.serviceName')"
            :options="headlessServices"
            required
          />
        </div>
      </div>
      <div>
        <Tabbed
          v-if="!isPodEdit"
          ref="containersTabbed"
          class="deployment-tabs"
          :show-tabs-add-remove="true"
          :default-tab="defaultTab"
          :flat="true"
          @changed="changed"
        >
          <Tab
            key="container"
            :label="t('workload.container.titles.containers')"
            name="container"
            :weight="100"
          >
            <div class="containers">
              <el-tag
                v-for="(tab, i) in allContainers"
                :key="tab[idKey]"
                :closable="allContainers.length > 1 && !isView"
                :class="{'active':i===activeContainerIndex }"
                @click="onActiveContainer(i)"
                @close="onClose(tab,i)"
              >
                <img
                  v-if="!tab.image"
                  src="../../assets/images/common/warn.svg"
                  style="width: 15px; height: 15px; position: relative; top: 4px;"
                >
                {{ tab.name }}
              </el-tag>
              <button
                v-if="!isView"
                type="button"
                class="btn-sm role-link"
                @click="onAdd"
              >
                <i class="el-icon-plus" />{{ t('generic.add') }}
              </button>
            </div>
            <div
              v-for="(tab, i) in allContainers"
              :key="tab[idKey]+i"
            >
              <Tabbed
                v-if="i===activeContainerIndex"
                :side-tabs="true"
                :weight="99"
              >
                <Tab
                  :label="t('pai.vmset.info')"
                  name="info"
                  :weight="tabWeightMap['general']"
                  :error="tabErrors.general"
                >
                  <div>
                    <div class="mb-20 row">
                      <div class="col span-6">
                        <LabeledRadioGroup
                          :value="`${isRealtime}`"
                          name="isRealtime"
                          :label="t('pai.edit.workload.container.type')"
                          :options="[{label:t('pai.edit.workload.container.normal'),value:'false'},{label:t('pai.edit.workload.container.realtime'),value:'true',disabled:!isRealtimeValid}]"
                          :row="true"
                          :required="true"
                          :mode="mode"
                          @input="onChangeType"
                        >
                          <template #after>
                            <i
                              v-tooltip="t('pai.edit.workload.tips.realtime')"
                              style="    margin-left: -15px;"
                              class="icon icon-info"
                            />
                          </template>
                        </LabeledRadioGroup>
                      </div>
                    </div>
                    <div
                      :style="{'align-items':'center'}"
                      class="row mb-20"
                    >
                      <div class="col span-6">
                        <LabeledInput
                          v-model="allContainers[activeContainerIndex].name"
                          :mode="mode"
                          :label="t('workload.container.containerName')"
                          @input="rcs[activeContainerIndex].name=$event"
                        />
                      </div>
                      <div class="col span-6">
                        <RadioGroup
                          v-if="!isRealtime"
                          :mode="mode"
                          :value="allContainers[activeContainerIndex]._init"
                          name="initContainer"
                          :options="[true, false]"
                          :labels="[t('workload.container.init'), t('workload.container.standard')]"
                          @input="updateInitContainer($event, allContainers[activeContainerIndex])"
                        />
                      </div>
                    </div>
                    <template v-if="isRealtime">
                      <div style="background: #FAFAFA;padding: 10px;margin-bottom: 10px">
                        <div class="row mb-20">
                          <div class="col span-6">
                            <LabeledInput
                              v-model="rcs[rcs.map(v => v.name).indexOf(allContainers[activeContainerIndex].name)].params.rt"
                              :mode="mode"
                              :label="t('pai.edit.workload.container.rt')"
                              placeholder="eg: -a -p 99"
                              :tooltip="t('pai.edit.workload.tips.rt')"
                            />
                          </div>
                          <div class="col span-6">
                            <LabeledInput
                              v-model="rcs[rcs.map(v => v.name).indexOf(allContainers[activeContainerIndex].name)].params.cpus"
                              :mode="mode"
                              label="cpus"
                              placeholder="eg: 0,1-3"
                              :tooltip="t('pai.edit.workload.tips.cpus')"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col span-6">
                            <LabeledInput
                              v-model="rcs[rcs.map(v => v.name).indexOf(allContainers[activeContainerIndex].name)].params.numa"
                              :mode="mode"
                              label="NUMA"
                              placeholder="eg: 0"
                              :tooltip="t('pai.edit.workload.tips.numa')"
                            />
                          </div>
                        </div>
                        <div
                          style="margin-top: 10px;display: flex;align-items: center;margin-right: 5px"
                        >
                          <i
                            style="font-size: 18px;line-height: 35px;"
                            class="icon icon-warning"
                          />
                          <div style="font-size: 12px;color: gray">
                            {{ t('pai.edit.workload.tips.config')?.split('/')[0] }}
                            <a
                              style="cursor: pointer"
                              @click="toAdvanced"
                            >
                              {{ t('pai.edit.workload.tips.config')?.split('/')[1] }}
                            </a>{{ t('pai.edit.workload.tips.config')?.split('/')[2] }}
                            <a
                              style="cursor: pointer"
                              @click="changeHash('container-resources');"
                            >
                              {{ t('pai.edit.workload.tips.config')?.split('/')[3] }}
                            </a>
                            {{ t('pai.edit.workload.tips.config')?.split('/')[4] }}
                          </div>
                        </div>
                      </div>
                    </template>
                    <h3>{{ t('workload.container.titles.image') }}</h3>
                    <el-radio-group
                      v-model="radio"
                      @change="handleSwitchImage"
                    >
                      <el-radio
                        v-model="radio"
                        label="1"
                      >
                        {{ t('pai.apps.container.customInput') }}
                      </el-radio>
                      <el-radio
                        v-model="radio"
                        label="2"
                      >
                        {{ t('pai.apps.container.selectMirror') }}
                      </el-radio>
                    </el-radio-group>
                    <div class="row mb-20">
                      <div class="col span-6">
                        <LabeledInput
                          v-if="radio==='1'"
                          v-model.trim="allContainers[activeContainerIndex].image"
                          :mode="mode"
                          :label="t('workload.container.image')"
                          :placeholder="t('generic.placeholder', {text: 'nginx:latest'}, true)"
                          :rules="fvGetAndReportPathRules('image')"
                        />
                        <LabeledSelect
                          v-if="radio==='2'"
                          v-model.trim="allContainers[activeContainerIndex].image"
                          :mode="mode"
                          :label="t('workload.container.image')"
                          :options="allImages"
                          :placeholder="t('generic.placeholder', {text: 'nginx:latest'}, true)"
                          :rules="fvGetAndReportPathRules('image')"
                        />
                      </div>
                      <div class="col span-6">
                        <LabeledSelect
                          v-model="allContainers[activeContainerIndex].imagePullPolicy"
                          :label="t('workload.container.imagePullPolicy')"
                          :options="pullPolicyOptions"
                          :mode="mode"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col span-6">
                        <LabeledSelect
                          v-model="imagePullSecrets"
                          :label="t('workload.container.imagePullSecrets')"
                          :multiple="true"
                          :taggable="true"
                          :options="imagePullNamespacedSecrets"
                          :mode="mode"
                          option-label="metadata.name"
                          :reduce="service=>service.metadata.name"
                          :create-option="createOption"
                        />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab
                  :label="t('workload.container.titles.general')"
                  name="general"
                  :weight="tabWeightMap['general']"
                  :rules="fvGetAndReportPathRules('metadata.name')"
                >
                  <div>
                    <h3>
                      {{ t('workload.container.ports.expose') }}
                      <i
                        v-tooltip="t('workload.container.ports.toolTip')"
                        class="icon icon-info"
                      />
                    </h3>
                    <p class="padded">
                      {{ t('workload.container.ports.description') }}
                    </p>
                    <div class="row">
                      <WorkloadPorts
                        v-model="allContainers[activeContainerIndex].ports"
                        :name="value.metadata.name"
                        :services="servicesOwned"
                        :mode="mode"
                      />
                    </div>
                  </div>
                  <div class="spacer" />
                  <div>
                    <h3>{{ t('workload.container.titles.command') }}</h3>
                    <Command
                      v-model="allContainers[activeContainerIndex]"
                      :secrets="namespacedSecrets"
                      :config-maps="namespacedConfigMaps"
                      :mode="mode"
                      :loading="isLoadingSecondaryResources"
                    />
                  </div>
                  <ServiceNameSelect
                    :value="podTemplateSpec.serviceAccountName"
                    :mode="mode"
                    :select-label="t('workload.serviceAccountName.label')"
                    :select-placeholder="t('workload.serviceAccountName.label')"
                    :options="namespacedServiceNames"
                    option-label="metadata.name"
                    :loading="isLoadingSecondaryResources"
                    @input="updateServiceAccount"
                  />
                  <div class="spacer" />
                  <div>
                    <h3>{{ t('workload.container.titles.lifecycle') }}</h3>
                    <LifecycleHooks
                      v-model="allContainers[activeContainerIndex].lifecycle"
                      :mode="mode"
                    />
                  </div>
                </Tab>
                <Tab
                  :label="t('workload.container.titles.resources')"
                  name="container-resources"
                  :weight="tabWeightMap['resources']"
                >
                  <!-- Resources and Limitations -->
                  <ContainerResourceLimit
                    v-model="flatResources"
                    :mode="mode"
                    :show-tip="false"
                  />
                </Tab>

                <Tab
                  v-if="!allContainers[activeContainerIndex]._init"
                  :label="t('workload.container.titles.healthCheck')"
                  name="healthCheck"
                  :weight="tabWeightMap['healthCheck']"
                >
                  <HealthCheck
                    :value="allContainers[activeContainerIndex]"
                    :mode="mode"
                    @input="Object.assign(allContainers[activeContainerIndex], $event)"
                  />
                </Tab>
                <Tab
                  :label="t('workload.container.titles.securityContext')"
                  name="securityContext"
                  :weight="tabWeightMap['securityContext']"
                >
                  <Security
                    v-model="allContainers[activeContainerIndex].securityContext"
                    :mode="mode"
                  />
                </Tab>
                <Tab
                  :label="t('workload.storage.title')"
                  name="storage"
                  :weight="tabWeightMap['storage']"
                >
                  <ContainerMountPaths
                    v-model="podTemplateSpec"
                    :namespace="value.metadata.namespace"
                    :register-before-hook="registerBeforeHook"
                    :mode="mode"
                    :secrets="namespacedSecrets"
                    :config-maps="namespacedConfigMaps"
                    :container="allContainers[activeContainerIndex]"
                    :podTemplateSpec="podTemplateSpec"
                    :save-pvc-hook-name="savePvcHookName"
                    :namespacedPvcs="pvcs"
                    @removePvcForm="clearPvcFormState"
                  />
                </Tab>
              </Tabbed>
            </div>
          </Tab>
          <Tab
            v-if="!isPod"
            :label="nameDisplayFor(type)"
            :name="nameDisplayFor(type)"
            :weight="99"
          >
            <Tabbed :side-tabs="true">
              <Tab
                name="labels"
                label-key="generic.labelsAndAnnotations"
                :weight="tabWeightMap['labels']"
              >
                <Labels
                  v-model="value"
                  :mode="mode"
                />
              </Tab>
              <Tab
                :label="t('workload.container.titles.upgrading')"
                name="upgrading"
                :weight="tabWeightMap['upgrading']"
              >
                <Job
                  v-if="isJob || isCronJob"
                  v-model="spec"
                  :mode="mode"
                  :type="type"
                />
                <Upgrading
                  v-else
                  v-model="spec"
                  :mode="mode"
                  :type="type"
                  :no-pod-spec="true"
                />
              </Tab>
            </Tabbed>
          </Tab>
          <Tab
            :label="t('pai.vmset.advancedOptions')"
            name="advanced"
            :weight="97"
          >
            <Tabbed :side-tabs="true">
              <Tab
                :label="t('workload.container.titles.resources')"
                name="resources"
                :weight="tabWeightMap['resources']"
              >
                <template v-if="podTemplateSpec">
                  <div>
                    <h3 class="mb-10">
                      <t k="workload.scheduling.titles.tolerations" />
                    </h3>
                    <div class="row">
                      <Tolerations
                        v-if="$route.hash.includes('resources')||$route.hash.includes('advanced')"
                        v-model="podTemplateSpec.tolerations"
                        :mode="mode"
                      />
                    </div>
                  </div>

                  <div>
                    <div class="spacer" />
                    <h3 class="mb-10">
                      <t k="workload.scheduling.titles.priority" />
                    </h3>
                    <div class="row">
                      <div class="col span-6">
                        <LabeledInput
                          v-model.number="podTemplateSpec.priority"
                          :mode="mode"
                          :label="t('workload.scheduling.priority.priority')"
                        />
                      </div>
                      <div class="col span-6">
                        <LabeledInput
                          v-model="podTemplateSpec.priorityClassName"
                          :mode="mode"
                          :label="t('workload.scheduling.priority.className')"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </Tab>
              <Tab
                :label="t('workload.container.titles.podScheduling')"
                name="podScheduling"
                :weight="tabWeightMap['podScheduling']"
              >
                <PodAffinity
                  :mode="mode"
                  :value="podTemplateSpec"
                  :nodes="allNodeObjects"
                  :loading="isLoadingSecondaryResources"
                />
              </Tab>
              <Tab
                :label="t('workload.container.titles.nodeScheduling')"
                name="nodeScheduling"
                :weight="tabWeightMap['nodeScheduling']"
              >
                <NodeScheduling
                  :mode="mode"
                  :value="podTemplateSpec"
                  :nodes="allNodes"
                  :loading="isLoadingSecondaryResources"
                />
              </Tab>
              <Tab
                :label="t('workload.container.titles.upgrading')"
                name="upgrading"
                :weight="tabWeightMap['upgrading']"
              >
                <Job
                  v-if="isJob || isCronJob"
                  v-model="spec"
                  :mode="mode"
                  :type="type"
                />
                <Upgrading
                  v-else
                  v-model="spec"
                  :mode="mode"
                  :type="type"
                  :no-deployment-spec="true"
                />
              </Tab>
              <Tab
                :label="t('workload.container.titles.securityContext')"
                name="securityContext"
                :weight="tabWeightMap['securityContext']"
              >
                <div>
                  <h3>{{ t('workload.container.security.podFsGroup') }}</h3>
                  <div class="row">
                    <div class="col span-6">
                      <LabeledInput
                        v-model.number="podFsGroup"
                        type="number"
                        :mode="mode"
                        :label="t('workload.container.security.fsGroup')"
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                v-if="isStatefulSet"
                :label="t('workload.container.titles.volumeClaimTemplates')"
                name="volumeClaimTemplates"
                :weight="tabWeightMap['volumeClaimTemplates']"
              >
                <VolumeClaimTemplate
                  v-model="spec"
                  :mode="mode"
                />
              </Tab>
              <Tab
                name="labels"
                label-key="generic.labelsAndAnnotations"
                :weight="tabWeightMap['labels']"
              >
                <div>
                  <h3>{{ t('workload.container.titles.podLabels') }}</h3>
                  <div class="row mb-20">
                    <KeyValue
                      key="labels"
                      v-model="podLabels"
                      :add-label="t('labels.addLabel')"
                      :mode="mode"
                      :read-allowed="false"
                      :protip="false"
                    />
                  </div>
                  <div class="spacer" />
                  <h3>{{ t('workload.container.titles.podAnnotations') }}</h3>
                  <div class="row">
                    <KeyValue
                      key="annotations"
                      v-model="podAnnotations"
                      :add-label="t('labels.addAnnotation')"
                      :mode="mode"
                      :read-allowed="false"
                      :protip="false"
                    />
                  </div>
                </div>
              </Tab>
              <Tab
                name="restartPolicy"
                label-key="pai.edit.workload.restartPolicy.label"
                :weight="tabWeightMap['restartPolicy']"
              >
                <div class="row">
                  <div class="col span-6">
                    <LabeledSelect
                      v-model="podTemplateSpec.restartPolicy"
                      :label="t('pai.edit.workload.restartPolicy.label')"
                      :options="restartPolicyOptions"
                      :mode="mode"
                    />
                  </div>
                </div>
              </Tab>
            </Tabbed>
          </Tab>
          <Tab
            :label="t('workload.storage.title')"
            :name="'storage'"
            :weight="98"
          >
            <Storage
              v-model="podTemplateSpec"
              :namespace="value.metadata.namespace"
              :register-before-hook="registerBeforeHook"
              :mode="mode"
              :secrets="namespacedSecrets"
              :config-maps="namespacedConfigMaps"
              :save-pvc-hook-name="savePvcHookName"
              :loading="isLoadingSecondaryResources"
              :namespaced-pvcs="pvcs"
              @removePvcForm="clearPvcFormState"
            />
          </Tab>
          <Tab
            :label="t('workload.container.titles.networking')"
            :name="'network'"
            :weight="98"
          >
            <div class="storageBox">
              <div v-if="networkVisible">
                <div class="row mt-10 mb-10">
                  <button
                    type="button"
                    class="btn role-primary"
                    style="margin-right: 10px"
                    @click="addNetwork"
                  >
                    <i class="el-icon-plus" />
                    {{ t('generic.add') }}
                  </button>
                  <button
                    type="button"
                    class="btn bg-error"
                    :disabled="isNetworkEmpty"
                    @click="removeAllNetworks"
                  >
                    <i class="el-icon-delete" />
                    {{ t('pai.labels.removeAll') }}
                  </button>
                </div>
                <el-table
                  :data="networkData"
                  style="width: 100%"
                >
                  <el-table-column
                    :label="t('nav.group.Networking')"
                    width="190"
                  >
                    <template slot-scope="scope">
                      <el-select
                        v-model="networkData[scope.$index].alias"
                        :placeholder="t('pai.edit.SelectPlaceholder')"
                        :disabled="scope.row.isDefault"
                        filterable
                        @change="onNetworkChange($event,scope.$index)"
                      >
                        <el-option
                          v-for="(item,i) in networkOptions"
                          :key="item.value+i"
                          :label="item.label"
                          :value="item.value"
                          :disabled="aliasArr.includes(item.label)"
                        />
                        <a
                          slot="empty"
                          style="display: block; width: 170px; height: 37px; line-height: 37px; text-align: center;"
                          @click="createNadLocation"
                        >
                          {{ t('pai.vmset.tips.emptyNad') }}
                        </a>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('pai.list.network.hostNetwork')"
                    prop="hostNetwork"
                    width="200"
                  >
                    <template slot-scope="scope">
                      <div class="tableCellBox">
                        {{ scope.row.isDefault || !scope.row.hostNetwork ? '-' : scope.row.hostNetwork }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('pai.detail.vmset.tab.networkManagement.type')"
                    prop="networkType"
                    width="200"
                  >
                    <template slot-scope="scope">
                      <div class="tableCellBox">
                        {{ scope.row.isDefault || !scope.row.networkType ? '-' : (scope.row.networkType === 'bridge' ? '桥接网络' : scope.row.networkType) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('pai.list.network.assignmentType')"
                    prop="assignmentType"
                    width="200"
                  >
                    <template slot-scope="scope">
                      <div
                        class="tableCellBox"
                      >
                        {{ scope.row.isDefault || !scope.row.assignmentType ? '-' : scope.row.assignmentType }}
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="ip"
                    min-width="200"
                  >
                    <!-- eslint-disable vue/no-unused-vars -->
                    <template
                      slot="header"
                      slot-scope="scope"
                    >
                      <!--eslint-enable-->
                      <div style="display: flex">
                        <span style="margin: 14px 6px 0px 0px;">
                          <el-tooltip
                            :content="t('pai.vmset.network.ipOffsetTip')"
                            effect="light"
                          >
                            <i
                              class="icon icon-info"
                              style="transform:scaleX(-1)"
                            />
                          </el-tooltip>
                          {{ t('pai.detail.vmset.tab.overview.ipAddress') }}
                        </span>
                        <span>
                          <LabeledInput
                            v-model.number="ipOffset"
                            :mode="mode"
                            label=""
                            class="col span-1 mb-20"
                            style="width: 110px;position: relative;top: 10px;"
                            type="number"
                            required
                            :min="0"
                            :placeholder="t('pai.vmset.network.editOffset')"
                            :disabled="mode==='edit'"
                            @blur="handleSetIpOffset"
                          />
                        </span>
                      </div>
                    </template>
                    <template slot-scope="scope">
                      <div
                        class="tableCellBox"
                      >
                        {{ scope.row.ip || '-' }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="">
                    <template slot-scope="scope">
                      <el-button
                        v-show="!scope.row.isDefault"
                        type="primary"
                        size="mini"
                        class="primaryBtn"
                        @click="removeNetwork(scope.row, scope.$index)"
                      >
                        {{ t('generic.remove') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                v-else
              >
                {{ t('pai.vmset.tips.noNetWork') }}，<a @click="handleMultus">{{ t('pai.vmset.tips.installLink') }}</a>
              </div>
            </div>
          </Tab>
        </Tabbed>
        <podEditForm
          v-else
          :value="value"
          :mode="mode"
        />
      </div>
    </CruResource>
  </form>
</template>

<style lang='scss'>
.container-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.type-placeholder {
  color: white;
  font-size: 2.5em;
  height: 100%;
  width: 100%;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.type-description {
  color: var(--input-label);
}

.next-dropdown {
  display: inline-block;
}

.tab-external-controls {
  display: flex;
  justify-content: right;
}

.tab-content-controls {
  display: flex;
  justify-content: right;
}

.tablist-controls {
  .role-link {
    padding: 10px 15px;
    min-height: unset;
    line-height: unset;

    &:focus {
      background: none;
      box-shadow: none;
    }

    &:hover {
      border: none;
    }
  }

}

.deployment-tabs {
  > .tabs.horizontal {
    border-bottom: 1px solid var(--border);
    margin-bottom: 10px;
  }
}
.padded {
  padding-bottom: 10px;
}
.containers{
  padding: 10px;
  width: 100%;

  .el-tag {
    margin: 5px;
    cursor: pointer;
    border-radius: 35px;
  }

  .active{
    border-color: var(--primary);
  }
}
.storageBox {
  background-color: #FAFAFA;
  border: 1px solid #DCDEE7;
  padding: 20px 10px;
  border-radius: 3px;
}
::v-deep .tab-header[data-v-1d6db493]{
  display: none;
}
</style>
<style lang="scss" scoped>
::v-deep .total{
  &:hover{
    background: unset;
  }
}
</style>
