<script>
import { EVENT, POD } from '@/shell/config/types';
import SortableTable from '@/shell/components/SortableTable';
import { mapGetters } from 'vuex';
export default {
  components: { SortableTable },
  props:      {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
  },
  data() {
    const inStore = this.$store.getters['currentStore'](EVENT);

    return {
      hasEvents:     this.$store.getters[`${ inStore }/schemaFor`](EVENT), // @TODO be smarter about which resources actually ever have events
      allEvents:     [],
      needEvents:    true,
      selectedTab:   'events',
      didLoadEvents: false,
      filterValue:   '',
      podList:       []
    };
  },
  async fetch() {
    this.allEvents = await this.$store.dispatch(`cluster/findAll`, { type: EVENT });
    this.podList = await this.$store.dispatch('cluster/findAll', { type: POD });
  },
  beforeDestroy() {
    this.$store.dispatch('cluster/forgetType', EVENT);
  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    showEvents() {
      return this.needEvents && this.hasEvents;
    },
    eventHeaders() {
      return [
        {
          name:  'reason',
          label: this.t('pai.detail.vmset.tab.eventLog.event'),
          value: 'reason',
          sort:  'reason',
        },
        {
          name:  'message',
          label: this.t('pai.detail.vmset.tab.eventLog.logInfo'),
          value: 'message',
          sort:  'message',
        },

        {
          name:  'type',
          label: this.t('pai.detail.vmset.tab.eventLog.type'),
          value: 'eventType',
          sort:  'eventType',
        },
        {
          name:          'date',
          label:         this.t('pai.detail.vmset.tab.eventLog.time'),
          value:         'date',
          sort:          'date:desc',
          formatter:     'LiveDate',
          formatterOpts: { addSuffix: true },
          width:         125
        },
      ];
    },
    events() {
      const podId = this.podList.find(item => item.metadata.name === this.podName)?.metadata?.uid;

      return this.allEvents.filter((event) => {
        return event.involvedObject?.uid === podId || event.involvedObject?.uid === this.value?.metadata?.uid;
      }).map((event) => {
        return {
          reason:    (`${ event.reason || this.t('generic.unknown') }${ event.count > 1 ? ` (${ event.count })` : '' }`).trim(),
          message:   event.message || this.t('generic.unknown'),
          date:      event.lastTimestamp || event.firstTimestamp || event.metadata.creationTimestamp,
          eventType: event.eventType
        };
      });
    },
  },
};
</script>

<template>
  <el-card>
    <el-row>
      <el-col :span="24">
        <el-input
          v-model="filterValue"
          :placeholder="t('pai.detail.vmset.filter')"
          prefix-icon="el-icon-search"
          size="small"
          style="float: right"
        />
      </el-col>
    </el-row>
    <el-row style="margin-top:10px">
      <el-col :span="24">
        <SortableTable
          v-if="selectedTab === 'events'"
          :rows="filterValue !== ''? events.filter(item => item.reason.includes(filterValue)):events"
          :headers="eventHeaders"
          key-field="id"
          :search="false"
          :table-actions="false"
          :row-actions="false"
          default-sort-by="date"
        />
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.eventTable{
  padding: 0 20px;
}
.el-input, .el-select, .el-input-number{
  width: 300px;
}
</style>
