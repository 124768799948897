<script>
import isEmpty from 'lodash/isEmpty';
import UnitInput from '@shell/components/form/UnitInput';
import { CONTAINER_DEFAULT_RESOURCE_LIMIT } from '@shell/config/labels-annotations';
import { cleanUp } from '@shell/utils/object';
import { _VIEW } from '@shell/config/query-params';

export default {
  components: { UnitInput },

  props: {
    mode: {
      type:    String,
      default: 'create'
    },

    namespace: {
      type:    Object,
      default: null
    },

    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },

    registerBeforeHook: {
      type:    Function,
      default: null
    },

    showTip: {
      type:    Boolean,
      default: true
    }
  },

  data() {
    const {
      limitsCpu, limitsMemory, requestsCpu, requestsMemory, limitsGpu
    } = this.value;

    return {
      limitsCpu,
      limitsMemory,
      requestsCpu,
      requestsMemory,
      limitsGpu,
      viewMode: _VIEW,
    };
  },

  watch: {
    value() {
      const {
        limitsCpu, limitsMemory, requestsCpu, requestsMemory, limitsGpu
      } = this.value;

      this.limitsCpu = limitsCpu;
      this.limitsMemory = limitsMemory;
      this.requestsCpu = requestsCpu;
      this.requestsMemory = requestsMemory;
      this.limitsGpu = limitsGpu;
    }
  },

  computed: {
    detailTopColumns() {
      return [
        {
          title: this.$store.getters['i18n/t']('generic.created'),
          name:  'created'
        },
      ];
    },
  },

  created() {
    if (this?.namespace?.id) {
      this.initLimits();
    }

    if (this.registerBeforeHook) {
      this.registerBeforeHook(this.updateBeforeSave);
    }
  },

  methods: {
    updateLimits() {
      const {
        limitsCpu,
        limitsMemory,
        requestsCpu,
        requestsMemory,
        limitsGpu
      } = this;

      this.$emit('input', cleanUp({
        limitsCpu,
        limitsMemory,
        requestsCpu,
        limitsGpu,
        requestsMemory
      }));
    },

    updateBeforeSave(value) {
      const {
        limitsCpu,
        limitsMemory,
        requestsCpu,
        requestsMemory,
        limitsGpu
      } = this;
      const namespace = this.namespace; // no deep copy in destructure proxy yet

      const out = cleanUp({
        limitsCpu,
        limitsMemory,
        requestsCpu,
        limitsGpu,
        requestsMemory
      });

      if (namespace) {
        namespace.setAnnotation(CONTAINER_DEFAULT_RESOURCE_LIMIT, JSON.stringify(out));
      }
    },

    initLimits() {
      const namespace = this.namespace;
      const defaults = namespace?.metadata?.annotations[CONTAINER_DEFAULT_RESOURCE_LIMIT];

      // Ember UI can set the defaults to the string literal 'null'
      if (!isEmpty(defaults) && defaults !== 'null') {
        const {
          limitsCpu,
          limitsMemory,
          requestsCpu,
          requestsMemory,
          limitsGpu
        } = JSON.parse(defaults);

        this.limitsCpu = limitsCpu;
        this.limitsMemory = limitsMemory;
        this.requestsCpu = requestsCpu;
        this.requestsMemory = requestsMemory;
        this.limitsGpu = limitsGpu;
      }
    },
  }

};
</script>

<template>
  <div>
    <div class="row">
      <div
        v-if="showTip"
        class="col span-12"
      >
        <p class="helper-text mb-10">
          <t
            v-if="mode === viewMode"
            k="containerResourceLimit.helpTextDetail"
          />
          <t
            v-else
            k="containerResourceLimit.helpText"
          />
        </p>
      </div>
    </div>
    <el-descriptions
      title=""
      direction="vertical"
      :column="2"
      :colon="false"
    >
      <!--    <div class="row mb-20">-->
      <!--      <span class="col span-6">-->
      <el-descriptions-item>
        <template slot="label">
          <span style="color: red">*</span>{{ t('containerResourceLimit.requestsCpu') }}
        </template>
        <UnitInput
          v-model="requestsCpu"
          :placeholder="t('containerResourceLimit.cpuPlaceholder')"
          label=""
          :mode="mode"
          :output-modifier="true"
          :base-unit="t('suffix.cores')"
          style="width: 60%"
          @input="updateLimits"
        />
      </el-descriptions-item>

      <!--      </span>-->
      <!--      <span class="col span-6">-->
      <el-descriptions-item>
        <template slot="label">
          <span style="color: red">*</span>{{ t('containerResourceLimit.requestsMemory') }}
        </template>
        <UnitInput
          v-model="requestsMemory"
          :placeholder="t('containerResourceLimit.memPlaceholder')"
          label=""
          :mode="mode"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          style="width: 60%"
          @input="updateLimits"
        />
      </el-descriptions-item>

      <!--      </span>-->
      <!--    </div>-->
    </el-descriptions>

    <!--    <div class="row mb-20">-->
    <el-descriptions
      title=""
      direction="vertical"
      :column="2"
      :colon="false"
    >
      <!--      <span class="col span-6">-->
      <el-descriptions-item>
        <template slot="label">
          <span style="color: red">*</span>{{ t('containerResourceLimit.limitsCpu') }}
        </template>
        <UnitInput
          v-model="limitsCpu"
          :placeholder="t('containerResourceLimit.cpuPlaceholder')"
          label=""
          :mode="mode"
          :output-modifier="true"
          :base-unit="t('suffix.cores')"
          style="width: 60%"
          @input="updateLimits"
        />
      </el-descriptions-item>

      <!--      </span>-->
      <!--      <span class="col span-6">-->
      <el-descriptions-item>
        <template slot="label">
          <span style="color: red">*</span>{{ t('containerResourceLimit.limitsMemory') }}
        </template>
        <UnitInput
          v-model="limitsMemory"
          :placeholder="t('containerResourceLimit.memPlaceholder')"
          label=""
          :mode="mode"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          style="width: 60%"
          @input="updateLimits"
        />
      </el-descriptions-item>

      <!--      </span>-->
    </el-descriptions>
    <!--    </div>-->
    <!--    <div class="row">-->
    <!--      <span class="col span-6">-->
    <!--        <UnitInput-->
    <!--          v-model="limitsGpu"-->
    <!--          :placeholder="t('containerResourceLimit.gpuPlaceholder')"-->
    <!--          :label="t('containerResourceLimit.limitsGpu')"-->
    <!--          :mode="mode"-->
    <!--          :base-unit="t('suffix.gpus')"-->
    <!--          @input="updateLimits"-->
    <!--        />-->
    <!--      </span>-->
    <!--    </div>-->
  </div>
</template>
