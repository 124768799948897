<script>
import { NAMESPACE, PVC, PV } from '@/shell/config/types';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { PVC_LABELS } from '@/pkg/pai/config/labels-annotations';
import { ALIAS } from '../config/labels-annotations';
import { PRODUCT_NAME as PAI } from '~/pkg/pai/config/pai';
export default {
  name: 'CloneVmModal',
  data() {
    return {
      namespaces: [],
      pvcs:       [],
      loading:    false,
      form:       {
        name:      '',
        namespace: ''
      },
      rules: {
        name: [{
          required: true,
          message:  this.t('pai.edit.placeholder') + this.t('pai.vmset.name'),
          trigger:  'blur'
        }],
        namespace: [{
          required: true,
          message:  this.t('pai.edit.SelectPlaceholder') + this.t('namespace.label'),
          trigger:  'blur'
        }],
      }
    };
  },
  async fetch() {
    this.namespaces = await this.$store.dispatch('cluster/findAll', { type: NAMESPACE });
    this.pvcs = await this.$store.dispatch('cluster/findAll', { type: PVC });
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'cloneVmModal';
    },
    value() {
      return this.$store.state['pai-common'].currentItem;
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else {
        return 'local';
      }
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    onConfirm() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          // 获取该云主机的所有pvc
          const pvcs = this.pvcs.filter(v => v.labels && v.labels[PVC_LABELS.MOUNT_VM] === v.metadata.name);
          const newVmName = `vm${ parseInt(new Date().getTime() / 1000) }`;

          this.loading = true;
          // 准备创建新的pvc
          const existsVolumeNames = [];

          this.value.spec?.volumes?.forEach((item) => {
            if (item?.persistentVolumeClaim?.claimName) {
              existsVolumeNames.push(item?.persistentVolumeClaim?.claimName);
            }
          });
          const templateVolumeNames = pvcs.filter(v => !existsVolumeNames.includes(v.metadata.name)).map(v => v.metadata.name);
          const newPvcs = [];

          try {
            for (const item of pvcs) {
              // 循环创建pvc
              const pvc = await this.$store.dispatch(`cluster/create`, { type: PVC });

              pvc.setLabel(PVC_LABELS.MOUNT_VM, newVmName);
              this.$set(pvc, 'spec', item.spec);
              this.$delete(pvc.spec, 'volumeName');
              pvc.metadata.namespace = this.value.metadata.namespace;
              this.$set(pvc.spec, 'dataSource', {
                kind: 'PersistentVolumeClaim',
                name: item.metadata.name,
              });
              this.$delete(pvc.spec, 'dataSourceRef');
              if (templateVolumeNames?.includes(item.metadata.name)) {
                const vmName = item.metadata.labels && item.metadata.labels[PVC_LABELS.MOUNT_VM] ? item.metadata.labels[PVC_LABELS.MOUNT_VM] : '';

                pvc.metadata.name = vmName ? item.metadata.name.replace(vmName, newVmName) : `clone-${ item.metadata.name }`;
              } else {
                pvc.metadata.name = `clone-${ item.metadata.name }`;
              }
              // 获取刚刚新建的pvc
              newPvcs.push(pvc.metadata.name);
              await pvc.save();
            }
            // 跨命名空间下进行复制
            if (this.form.namespace !== this.value.metadata.namespace) {
              const allPvs = await this.$store.dispatch('cluster/findAll', { type: PV, opt: { force: true } });

              for await (const item of newPvcs) {
                // 获取并修改刚刚新建的pvc对应的pv
                if (item.spec.volumeName) {
                  const pv = allPvs.find(v => item.spec.volumeName === v.metadata.name);

                  pv.spec.persistentVolumeReclaimPolicy = 'Retain';
                  await item.save();
                }
                // 在目标ns中创建pvc，指明pv，删除原pvc
                const pvc = await this.$store.dispatch(`cluster/create`, { type: PVC });

                pvc.setLabel(PVC_LABELS.MOUNT_VM, newVmName);
                this.$set(pvc, 'spec', item.spec);
                this.$set(pvc, 'status', item.status);
                this.$delete(pvc.spec, 'volumeName');
                pvc.metadata.namespace = this.form.namespace;
                this.$set(pvc.spec, 'dataSource', {
                  kind: 'PersistentVolumeClaim',
                  name: item.metadata.name,
                });
                if (templateVolumeNames.includes(item.metadata.name)) {
                  const vmName = item.metadata.labels && item.metadata.labels[PVC_LABELS.MOUNT_VM] ? item.metadata.labels[PVC_LABELS.MOUNT_VM] : '';

                  pvc.metadata.name = item.metadata.name.replace(vmName, newVmName);
                } else {
                  pvc.metadata.name = `clone-${ item.metadata.name }`;
                }
                this.$set(pvc.spec, 'volumeName', item.spec.volumeName);
                await item.remove();
                await pvc.save();
              }
            }
            const initialModel = await this.$store.dispatch(`cluster/create`, {
              type:     PAI_RESOURCES.VMSET,
              metadata: this.value.metadata,
              spec:     this.value.spec
            });

            initialModel.metadata.name = newVmName;
            initialModel.metadata.namespace = this.form.namespace;
            initialModel.setAnnotation(ALIAS, this.form.name);
            this.$delete(initialModel.metadata, 'managedFields');
            this.$delete(initialModel.metadata, 'resourceVersion');
            // 修改云主机的磁盘名称
            if (initialModel.spec.volumes?.length > 0) {
              initialModel.spec.volumes.forEach((v, i) => {
                initialModel.spec.volumes[i].name = `clone-${ this.value.spec.volumes[i].name }`;
              });
            }
            await initialModel.save();
            await this.$message({
              type:    'success',
              message: this.t('pai.vmset.tips.clone.waiting')
            });
            await this.handleClose();
            this.loading = false;
            await this.$router.push({
              name:   `${ PAI }-c-cluster-resource`,
              params: {
                product:  PAI,
                resource: PAI_RESOURCES.VMSET,
                cluster:  this.currentCluster,
              },
            });
          } catch (e) {
            await this.$message({
              type:    'warning',
              message: e.message
            });
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<template>
  <el-dialog
    :title="t('dialog.cloneDialog.title')"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :inline-message="true"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="auto"
    >
      <el-form-item
        :label="t('pai.vmset.name')"
        prop="name"
      >
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item
        :label="t('namespace.selectNamespace')"
        prop="namespace"
      >
        <el-select
          v-model="form.namespace"
          filterable
        >
          <el-option
            v-for="(item) in namespaces.map(v=>v.metadata.name)"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >{{ t('pai.detail.vmset.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
.el-input, .el-select{
  width: 100%;
}
::v-deep .el-form-item__label {
  line-height: unset;
}
</style>
