import { REALTIME, VIRT } from '~/pkg/pai/config/labels-annotations';
import { POD, WORKLOAD_TYPES } from '@shell/config/types';

const ContainerTypeMixin = Base => class extends Base {
  get isVmsets() {
    return this.metadata && this.metadata.labels && this.metadata.labels[VIRT] === 'true';
  }

  get isKata() {
    return this.spec && this.spec.runtimeClassName && this.spec.runtimeClassName.includes('kata');
  }

  get isRealtime() {
    if (this.type === POD) {
      return !!(this.metadata && this.metadata.annotations && this.metadata.annotations[REALTIME] && this.metadata.annotations[REALTIME].length > 0);
    } else if (this.type === WORKLOAD_TYPES.CRON_JOB) {
      return !!(this?.spec?.jobTemplate?.spec?.template?.metadata?.annotations && this?.spec?.jobTemplate?.spec?.template?.metadata?.annotations[REALTIME] && this?.spec?.jobTemplate?.spec?.template?.metadata?.annotations[REALTIME].length > 0);
    } else {
      return !!(this?.spec?.template?.metadata?.annotations && this?.spec?.template?.metadata?.annotations[REALTIME] && this?.spec?.template?.metadata?.annotations[REALTIME].length > 0);
    }
  }

  get podType() {
    if (this.isVmsets) {
      return 'vmset';
    }
    if (this.isKata) {
      return 'kata';
    }
    if (this.isRealtime) {
      return 'realtime';
    }

    return 'default';
  }
};

export default ContainerTypeMixin;
