import { render, staticRenderFns } from "./k8s.cni.cncf.io.networkattachmentdefinition.vue?vue&type=template&id=07d1a6b1&scoped=true&"
import script from "./k8s.cni.cncf.io.networkattachmentdefinition.vue?vue&type=script&lang=js&"
export * from "./k8s.cni.cncf.io.networkattachmentdefinition.vue?vue&type=script&lang=js&"
import style0 from "./k8s.cni.cncf.io.networkattachmentdefinition.vue?vue&type=style&index=0&id=07d1a6b1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d1a6b1",
  null
  
)

export default component.exports