<script>
import { PAI_CATALOG } from '../../../config/types';
import { compare } from '@shell/utils/sort';
import { mapGetters, mapState } from 'vuex';
import { PRODUCT_NAME as PAI, PRODUCT_NAME } from '../../../config/pai';
import { CATALOG } from '@shell/config/types';
import { omitString } from '../../../utils/string';
import { colorForState, stateDisplay } from '@shell/plugins/dashboard-store/resource-class';
import dayjs from 'dayjs';
import { allHashSettled } from '@shell/utils/promise';
import { CATALOG as CATALOG_ANNOTATIONS } from '@shell/config/labels-annotations';
import { VM_POWER_STATES } from '../../../plugins/pai-resource-class';

export default {
  props: {
    cluster: {
      type:     String,
      required: true,
    }
  },
  async fetch() {
    // 已安装应用-容器应用
    this.installedContainers = await this.$store.dispatch('cluster/findAll', {
      type: CATALOG.APP,
      opt:  { force: true },
    });
    const repos = await this.$store.dispatch('cluster/findAll', { type: CATALOG.CLUSTER_REPO, opt: { force: true } });
    const promises = {};
    let all = [];

    for await (const repo of repos) {
      if (repo.canLoad) {
        promises[repo.metadata.name] = repo.followLink('index');
      }
    }
    const res = await allHashSettled(promises);

    for (const key of Object.keys(res)) {
      const value = res[key];

      if (value.status === 'rejected') {
        continue;
      }
      if (value.value.entries) {
        for (const item in value.value.entries) {
          const versions = value.value.entries[item];

          if (versions && versions.length > 0) {
            all = [...all, {
              ...versions[0],
              repoType:         'cluster',
              repoName:         key,
              chartName:        item,
              chartNameDisplay: versions[0].annotations?.[CATALOG_ANNOTATIONS.DISPLAY_NAME] || versions[0].name,
              versions,
            }];
          }
        }
      }
    }
    this.containers = all.filter((obj, index) => all.findIndex(item => item.chartName === obj.chartName) === index);
    await this.setData();
  },
  data() {
    return {
      installedContainers:      [],
      installedContainerResult: [],
      filterValue:              '',
      cardCount:                7,
      loadImgSrc:               `this.src = "${ require('../../../assets/images/home/empty.svg') }"`,
      PRODUCT_NAME,
      PAI_CATALOG,
      currentContainer:         '',
      containers:               [],
    };
  },
  computed: {
    ...mapState('pai-common', ['currentContainerRow']),
    ...mapGetters(['currentCluster']),
    filterNamespaces() {
      return this.$parent.getFilterNamespaces();
    },
    getLength() {
      return (arr) => {
        return arr ? arr.length : 0;
      };
    },
    getArr() {
      return (arr) => {
        return arr;
      };
    },
    allContainerVersions() {
      const allContainerVersions = {};

      this.containers.forEach((container) => {
        allContainerVersions[container.chartName] = container.versions;
      });

      return allContainerVersions;
    },
    stateLabel() {
      return this.t(VM_POWER_STATES[this.value.powerState].labelKey);
    },
  },
  methods: {
    omitString,
    colorForState,
    stateDisplay,
    setData() {
      const filterNamespaces = this.filterNamespaces;

      this.installedContainers = this.installedContainers.sort((a, b) => {
        return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
      }).sort((a, b) => {
        return compare(b.starTimeStamp, a.starTimeStamp);
      });
      if (filterNamespaces.length > 0) {
        this.installedContainerResult = this.installedContainers.filter(v => filterNamespaces.includes(v.metadata.namespace));
      } else {
        this.installedContainerResult = this.installedContainers;
      }
    },
    async onAction(app, state) {
      if (state === 'console') {
        this.$router.push({
          name:   `${ PRODUCT_NAME }-c-cluster-resource-namespace-id`,
          params: {
            cluster:   this.currentCluster.id,
            product:   PRODUCT_NAME,
            resource:  PAI_CATALOG.APP,
            id:        app.name,
            namespace: app.namespace,
          },
        });
      } else if (state === 'upgrade') {
        await this.$store.dispatch('catalog/load', { force: true, reset: true });
        app.goToPaiUpgrade();
      }
    },
    async onActionStar(item) {
      try {
        await item.setStar();
        this.installedContainerResult = this.installedContainerResult.sort((a, b) => {
          return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
        }).sort((a, b) => {
          return compare(b.starTimeStamp, a.starTimeStamp);
        });
      } catch (e) {}
    },
    showMore() {
      this.$store.dispatch('pai-common/updateState', { currentContainerRow: this.currentContainerRow + 1 });
    },
    onWindowResize() {
      if (this.$refs.cardContainer && this.$refs.cardContainer.clientWidth) {
        this.cardCount = parseInt(this.$refs.cardContainer.clientWidth / 255);
      }
    },
    loadSrc(value) {
      if (value.spec.chart.metadata.icon) {
        return value.spec.chart.metadata.icon;
      } else {
        return this.loadImgSrc;
      }
    },
    goList() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource`,
        params: {
          product:  PAI,
          cluster:  this.cluster,
          resource: PAI_CATALOG.APP,
        },
      });
    },
  },
  watch: {
    '$route.query.q'(val) {
      this.filterValue = val || '';
    },
    filterNamespaces: {
      deep:      true,
      immediate: true,
      handler() {
        this.setData();
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
    this.$nextTick(() => {
      if (this.$refs.cardContainer && this.$refs.cardContainer.clientWidth) {
        this.cardCount = this.$refs.cardContainer.clientWidth / 255;
      }
    });
  },
};
</script>
<template>
  <div>
    <div class="title">
      <div class="line" />
      {{
        t('pai.apps.container.label')
      }}（{{ getLength(installedContainerResult.filter(v => v.metadata.name.includes(filterValue))) }}）
      <div class="line" />
    </div>
    <div
      ref="cardContainer"
      v-loading="$fetchState.pending"
      class="listContainer"
    >
      <div
        v-for="container in getArr(installedContainerResult).filter(v=>v.metadata.name.includes(filterValue)).slice(0,currentContainerRow*cardCount)"
        :key="container.metadata.uid"
      >
        <div class="card">
          <i
            :class="container.starClass"
            @click="onActionStar(container)"
          />
          <div class="container-top">
            <div class="image">
              <img
                :src="loadSrc(container)"
                alt=""
                :onerror="loadImgSrc"
              >
            </div>
            <div class="name">
              <nuxt-link
                :title="container.metadata.name"
                :to="{name:'pai-c-cluster-resource-namespace-id',params:{product:PRODUCT_NAME,resource:PAI_CATALOG.APP,cluster:currentCluster.id,namespace:container.metadata.namespace,id:container.metadata.name}}"
              >
                {{ omitString(container.metadata.name,20) }}
              </nuxt-link>
              <div>
                <div
                  class="state"
                  :class="`${colorForState(container.status.summary.state).replace('text-', 'bg-')}`"
                />
                {{ t(`apps.installed.status.${container.status.summary.state}`) }}
              </div>
            </div>
          </div>
          <div class="line" />
          <div
            class="bottom"
            @mouseover="currentContainer=container.metadata.name"
            @mouseleave="currentContainer=''"
          >
            <el-row v-show="currentContainer!==container.metadata.name">
              <el-col :span="12">
                {{ t('pai.vmset.resourceNum') }}：
              </el-col>
              <el-col :span="12">
                {{ getLength(container.spec.resources) }}
              </el-col>
              <el-col :span="12">
                Chart：
              </el-col>
              <el-col :span="12">
                {{ container.spec.chart.metadata.version }}
              </el-col>
              <el-col :span="12">
                {{
                  (allContainerVersions[container.metadata.name] && getLength(allContainerVersions[container.metadata.name]) > 0) ? `${ t('pai.apps.container.upgradable') }` : t('pai.apps.container.notUpgradable')
                }}：
              </el-col>
              <el-col :span="12">
                {{
                  (allContainerVersions[container.metadata.name] && getLength(allContainerVersions[container.metadata.name]) > 0) ? getLength(allContainerVersions[container.metadata.name]) : ''
                }}
              </el-col>
            </el-row>
            <div
              v-show="currentContainer===container.metadata.name"
              class="action"
            >
              <div @click="onAction(container,'console')">
                <img
                  src="../../../assets/images/home/console.svg"
                  alt=""
                >{{ t('pai.detail.vmset.control') }}
              </div>
              <div />
              <div @click="onAction(container,'upgrade')">
                <img
                  src="../../../assets/images/home/upgrade.png"
                  alt=""
                >{{ t('asyncButton.upgrade.action') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more">
      <el-button
        v-show="currentContainerRow < getLength(installedContainerResult.filter(v=>v.metadata.name.includes(filterValue)))/cardCount && getLength(getArr(installedContainerResult).filter(v=>v.metadata.name.includes(filterValue))) !== 0"
        @click="showMore('container')"
      >
        {{ t('pai.labels.loadMore') }}>>
      </el-button>
      <el-button @click="goList">
        {{ t('pai.labels.viewMore') }}>>
      </el-button>
      <el-empty
        v-show="getLength(getArr(installedContainerResult).filter(v=>v.metadata.name.includes(filterValue))) === 0"
        :image-size="60"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.title {
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #0C5489;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin: 10px 0;
  .line {
    height: 50%;
    width: 45%;
    border-bottom: 1px dashed #cccccc;
  }
}
.listContainer{
  display:grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 255px);
  grid-gap: 5px;
}
.card {
  box-shadow:
          0 1px 1px rgb(0 0 0 / 0.25),
          0 2px 2px rgb(0 0 0 / 0.20),
          0 4px 4px rgb(0 0 0 / 0.15),
          0 8px 8px rgb(0 0 0 / 0.10),
          0 16px 16px rgb(0 0 0 / 0.05);
  max-width: 260px;
  width: 255px;
  margin-right: 10px;
  height: 140px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(239, 239, 239, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  border: 1px solid rgba(206, 206, 206, 1);
  padding: 0 10px;
  margin-bottom: 10px;

  i {
    float: right;
    cursor: pointer;
    color: #FCCA00;
  }

  .container-top {
    height: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .image {
      flex: 0.3;
      img{
        width: 40px;
      }
    }

    .name {
      flex: 0.6;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      white-space: nowrap;
      overflow: hidden;

      div {
        white-space: nowrap;
        overflow: hidden;
        max-width: 150px;
        text-overflow: ellipsis;
      }

      .active {
        cursor: pointer;
        color: #3d98d3;
      }

      .notActive {
        cursor: none;
        color: #000000;
      }

      .state {
        display: inline-block;
        margin-right: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        font-family: SourceHanSansSC-regular, serif;
      }
    }
  }

  .line {
    border-bottom: 2px solid #246FA5;
    height: 5%;
  }

  .bottom {
    font-size: 12px;
    text-align: left;
    height: 55%;
    display: flex;
    align-items: center;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .disabled {
        cursor: default;
        color: #bbb;
        pointer-events: none;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }
    }

    img {
      width: 22px;
      margin-bottom: 5px;
    }
  }
}

.more {
  text-align: center;

  .el-button {
    background: #0C5489;
    color: #fff;
  }
}
</style>
