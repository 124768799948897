import { RouteConfig } from 'vue-router';
import { PRODUCT_NAME, PRODUCT_NAME as PAI } from '../config/pai';
import { PRODUCT_NAME as PAI_PLATFORM } from '../config/platform';
import { PRODUCT_NAME as DEPLOY } from '../config/deploy';
import ListResource from '../pages/c/_cluster/_resource/index.vue';
import CreateResource from '../pages/c/_cluster/_resource/create.vue';
import ViewResource from '../pages/c/_cluster/_resource/_id.vue';
import Middleware from '../pages/index.vue';
import Home from '../pages/home/index.vue';
import Login from '../pages/auth/login.vue';
import Setup from '../pages/auth/setup.vue';
import Prefs from '../pages/prefs.vue';
import Overview from '../pages/overview/index.vue';
import ClusterOverview from '../pages/c/_cluster/overview/index.vue';
import PlatformOverview from '../pages/c/_cluster/platform/overview/index.vue';
import Charts from '../pages/c/_cluster/apps/charts/index.vue';
import ProjectsNamespaces from '../pages/c/_cluster/projectsnamespaces.vue';
import Members from '../pages/c/_cluster/members/index.vue';
import Deploy from '../pages/c/_cluster/deploy/index.vue';

import AuthRoles from '../pages/c/_cluster/auths/roles/index.vue';
import AuthConfig from '../pages/c/_cluster/auths/config/index.vue';
import CreateRoles from '../pages/c/_cluster/auths/roles/_resource/create.vue';
import ViewAuthConfig from '../pages/c/_cluster/auths/config/_id.vue';
import ViewRoleResource from '../pages/c/_cluster/auths/roles/_resource/_id.vue';
import ViewNamespaceResource from '../pages/c/_cluster/_resource/_namespace/_id.vue';
import ViewChart from '../pages/c/_cluster/apps/charts/chart.vue';
import ViewApp from '../pages/c/_cluster/apps/charts/app.vue';
import InstallChart from '../pages/c/_cluster/apps/charts/install.vue';
import Lb from '../pages/c/_cluster/lb/index.vue';
import Tools from '../pages/c/_cluster/explorer/tools/index.vue';
import ToolsPage from '../pages/c/_cluster/explorer/tools/pages/_page.vue';
import CreateCluster from '../pages/manage/cluster/_resource/create.vue';
import ViewCluster from '../pages/manage/cluster/_resource/_namespace/_id.vue';

const routes: RouteConfig[] = [
  {
    name:      `pai`,
    path:      `/pai`,
    component: Middleware,
  },
  {
    name:      `pai-home`,
    path:      `/pai/home`,
    component: Home,
  },
  {
    name:      `pai-prefs`,
    path:      `/pai/prefs`,
    component: Prefs,
  },
  {
    name:      'pai-overview',
    path:      '/pai/overview',
    component: Overview,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-platform-overview`,
    path:      `/:product/c/:cluster/platform/overview`,
    component: PlatformOverview,
  },
  {
    name:      `pai-middleware`,
    path:      `/pai/middleware`,
    component: Middleware,
  },
  {
    name:      `pai-auth-setup`,
    path:      `/pai/auth/setup`,
    component: Setup,
  },
  {
    name:      `pai-login`,
    path:      `/pai/login`,
    component: Login,
  },
  {
    name:      `manage-cluster-resource-create`,
    path:      `/manage/cluster/:resource/create`,
    component: CreateCluster,
  },
  {
    name:      `manage-cluster-resource-namespace-id`,
    path:      `/manage/cluster/:resource/:namespace/:id`,
    component: ViewCluster,
  },
  {
    name:      `${ PAI }-c-cluster-apps-charts`,
    path:      `/:product/c/:cluster/apps/charts`,
    component: Charts,
  },
  {
    name:      `${ PAI }-c-cluster-apps-charts-chart`,
    path:      `/:product/c/:cluster/apps/charts/chart`,
    component: ViewChart,
  },
  {
    name:      `${ PAI }-c-cluster-apps-charts-app`,
    path:      `/:product/c/:cluster/apps/charts/app`,
    component: ViewApp,
  },
  {
    name:      `${ PAI }-c-cluster-apps-charts-install`,
    path:      `/:product/c/:cluster/apps/charts/install`,
    component: InstallChart,
  },
  {
    name:      `${ PAI }-c-cluster-explorer-tools`,
    path:      `/:product/c/:cluster/explorer/tools`,
    component: Tools,
  },
  {
    name:      `${ PRODUCT_NAME }-c-cluster-explorer-tools-pages-page`,
    path:      `/:product/c/:cluster/explorer/tools/pages/_page`,
    component: ToolsPage,
  },
  {
    name:      `${ PAI }-c-cluster-projectsnamespaces`,
    path:      `/:product/c/:cluster/projectsnamespaces`,
    component: ProjectsNamespaces,
  },
  {
    name:      `${ PAI }-c-cluster-members`,
    path:      `/:product/c/:cluster/members`,
    component: Members,
  },
  {
    name:      `${ PAI }-c-cluster-lb`,
    path:      `/:product/c/:cluster/lb`,
    component: Lb,
  },
  {
    name:      `${ DEPLOY }-c-cluster-deploy`,
    path:      `/:product/c/:cluster/deploy`,
    component: Deploy,
  },
  {
    name:      `${ PAI }-c-cluster-overview`,
    path:      `/:product/c/:cluster/overview`,
    component: ClusterOverview,
  },

  {
    name:      `${ PAI_PLATFORM }-c-cluster-auths-config`,
    path:      `/:product/c/:cluster/auths/config`,
    component: AuthConfig,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-auths-roles`,
    path:      `/:product/c/:cluster/auths/roles`,
    component: AuthRoles,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-auths-roles-resource-create`,
    path:      `/:product/c/:cluster/auths/roles/:resource/create`,
    component: CreateRoles,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-auths-roles-resource-id`,
    path:      `/:product/c/:cluster/auths/roles/:resource/:id`,
    component: ViewRoleResource,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-auths-config-id`,
    path:      `/:product/c/:cluster/auths/config/:id`,
    component: ViewAuthConfig,
  },

  {
    name:      `${ PAI_PLATFORM }-c-cluster-resource`,
    path:      `/:product/c/:cluster/:resource`,
    component: ListResource,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-resource-create`,
    path:      `/:product/c/:cluster/:resource/create`,
    component: CreateResource,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-resource-id`,
    path:      `/:product/c/:cluster/:resource/:id`,
    component: ViewResource,
  },
  {
    name:      `${ PAI_PLATFORM }-c-cluster-resource-namespace-id`,
    path:      `/:product/c/:cluster/:resource/:namespace/:id`,
    component: ViewNamespaceResource,
  },

  // deploy

  {
    name:      `${ DEPLOY }-c-cluster-resource`,
    path:      `/:product/c/:cluster/:resource`,
    component: ListResource,
  },
  {
    name:      `${ DEPLOY }-c-cluster-resource-create`,
    path:      `/:product/c/:cluster/:resource/create`,
    component: CreateResource,
  },
  {
    name:      `${ DEPLOY }-c-cluster-resource-id`,
    path:      `/:product/c/:cluster/:resource/:id`,
    component: ViewResource,
  },
  {
    name:      `${ DEPLOY }-c-cluster-resource-namespace-id`,
    path:      `/:product/c/:cluster/:resource/:namespace/:id`,
    component: ViewNamespaceResource,
  },

  {
    name:      `${ PAI }-c-cluster-resource`,
    path:      `/:product/c/:cluster/:resource`,
    component: ListResource,
  },
  {
    name:      `${ PAI }-c-cluster-resource-create`,
    path:      `/:product/c/:cluster/:resource/create`,
    component: CreateResource,
  },
  {
    name:      `${ PAI }-c-cluster-resource-id`,
    path:      `/:product/c/:cluster/:resource/:id`,
    component: ViewResource,
  },
  {
    name:      `${ PAI }-c-cluster-resource-namespace-id`,
    path:      `/:product/c/:cluster/:resource/:namespace/:id`,
    component: ViewNamespaceResource,
  },
];

export default routes;
