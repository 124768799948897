export const ALIAS = 'com.tdology/alias';
export const COLLECTION = 'com.tdology/collection';
export const BUILT_IN = 'com.tdology/builtin';
export const VIRT = 'com.tdology.virt';
export const REALTIME = 'tdology.com/realtime';

export const IMAGE = {
  SIZE:       'tdology.com/os-size',
  ARCH:       'kubernetes.io/arch',
  AGENT:      'io.katacontainers.config.agent.name',
  OS:         'io.katacontainers.config.hypervisor.os',
  NAME:       'com.tdology.virt.vmimages',
  DEPEND_VM:  'depend_vm',
  DEPEND_POD: 'depend_pod',
  LOGO:       'tdology.com/logo',
  ISO:        'com.tdology.pvc.iso',
  TOOL:       'com.tdology/toolkit',
  MONITORING: 'com.tdology/minitoring'
};

export const PVC_LABELS = {
  OS:        'com.tdology.pvc.vmos',
  MOUNT_POD: 'com.tdology.pvc.mounto',
  MOUNT_VM:  'com.tdology.virt.vmsets',
};

export const VMSET_ANNOTATIONS = { IMAGE_ALIAS: 'com.tdology.virt.vmimages/alias' };

export const NAD_ANNOTATIONS = {
  TRUNKS:  'com.tdology.nad.trunks',
  VLANS:   'com.tdology.nad.vlans',
  MASTERS: 'com.tdology.nad.masters',
  BRIDGES: 'com.tdology.nad.bridges',
};
export const CLUSTER_ANNOTATIONS = { COLLECTION: 'com.kubernetes.pods/collection' };

export const MACHINE_ANNOTATIONS = {
  ACTION: 'picloud/action', ROLE: 'picloud/tmpl-node', MAIN_NIC: 'picloud/nic'
};

export const POD_LABELS = { JOB_NAME: 'job-name' };
