<template>
  <div>
    <div class="filter">
      <el-input
        v-model="filterValue"
        :placeholder="t('pai.detail.vmset.placeholderInput')"
        prefix-icon="el-icon-search"
      />
    </div>
    <el-table
      border
      :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
    >
      <el-table-column
        v-for="column in columns"
        :key="column.prop"
        :label="column.label"
        :prop="column.prop"
      >
        <template slot-scope="scope">
          <el-input
            v-if="(column.prop === 'backendPort' && currentService === scope.row.namespace + scope.row.name + scope.row.port)"
            v-model="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)[scope.$index].backendPort"
          />
          <span v-else>{{ scope.row[column.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="t('pai.detail.vmset.operate')">
        <template slot-scope="scope">
          <button
            v-show="currentService !== scope.row.namespace + scope.row.name + scope.row.port"
            class="btn"
            @click="edit(scope.row)"
          >
            {{ t('action.edit') }}
          </button>
          <template v-if="currentService === scope.row.namespace + scope.row.name + scope.row.port">
            <button
              class="btn"
              @click="cancel"
            >
              {{ t('generic.cancel') }}
            </button>
            <button
              class="btn role-primary"
              @click="save(scope.row)"
            >
              {{ t('generic.save') }}
            </button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="block"
      style="margin-top: 15px"
    >
      <el-pagination
        align="center"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!--    <div class="bottom">
      <button
        v-show="currentService === 'view'"
        class="btn"
        @click="edit"
      >
        {{ t('action.edit') }}
      </button>

      <template v-if="currentService === 'edit'">
        <button
          class="btn"
          @click="cancel"
        >
          {{ t('generic.cancel') }}
        </button>
        <button
          class="btn role-primary"
          @click="save"
        >
          {{ t('generic.save') }}
        </button>
      </template>
    </div>-->
  </div>
</template>

<script>
import { SERVICE } from '@shell/config/types';
export default {
  async fetch() {
    this.allServices = await this.$store.dispatch('cluster/findAll', { type: SERVICE });
  },
  data() {
    const columns = [
      { prop: 'namespace', label: this.t('namespace.label') },
      { prop: 'name', label: this.t('workload.detail.services') },
      // { prop: 'status', label: '服务状态' },
      { prop: 'proto', label: this.t('pai.lb.proto') },
      // { prop: 'ip', label: 'IP' },
      { prop: 'port', label: this.t('servicesPage.ips.define') },
      { prop: 'backendPort', label: this.t('pai.lb.outerPort') },
      /*      { prop: 'podNum', label: '容器组数量' },
      { prop: 'bytesIn', label: '入站流量' },
      { prop: 'bytesOut', label: '出站流量' },
      { prop: 'activeNum', label: '当前连接数' }, */
    ];

    return {
      columns,
      tableData:      [],
      data:           [],
      currentPage:    1,
      pageSize:       10,
      currentService: '',
      filterValue:    '',
      allServices:    [],
    };
  },
  computed: {
    status() {
      return this.$store.state['pai-common'].lbStatus;
    },
    services() {
      return this.$store.state['pai-common'].lbServices;
    },
  },
  watch: {
    /*    status(nue) {
      if (nue.length > 0 && nue[0].svcs) {
        nue[0].svcs.forEach((v) => {
          const namespace = '';
          // name都是rr，使用ip+port代替
          const name = `${ v.frontend.addr }:${ v.frontend.port }`;
          // banckends为空代表有故障
          const status = v.banckends.length > 0 ? 'Normal' : 'Error';
          const proto = v.frontend.proto;
          const ip = v.frontend.addr;
          const port = v.frontend.port;
          const backendPort = '';
          const podNum = v.banckends.length ? v.banckends.length : 0;
          const activeNum = v.frontend.connections;
          let bytesIn = 0;
          let bytesOut = 0;

          v.banckends.forEach((value) => {
            if (value.bytesIn) {
              bytesIn += value.bytesIn;
            }
            if (value.bytesOut) {
              bytesOut += value.bytesOut;
            }
          });

          bytesIn = bytesIn * 8;
          bytesOut = bytesOut * 8;

          this.tableData.push({
            namespace, name, status, proto, ip, port, backendPort, podNum, bytesIn, bytesOut, activeNum
          });
        });
      }
    }, */
    services(nue) {
      const data = [];

      for (const key in nue) {
        const namespace = key.split('/')[0];
        const name = key.split('/')[1].split('#')[0];
        const proto = key.split('/')[1].split('#')[1];
        const port = key.split('/')[2];
        const backendPort = nue[key];

        data.push({
          namespace, name, proto, port, backendPort
        });
      }
      this.data = data;
      this.tableData = data;
    },
    filterValue(nue) {
      this.tableData = this.data.filter((v) => {
        return v.namespace.includes(nue) || v.name.includes(nue) || v.proto.includes(nue) || v.port.includes(nue) || v.backendPort.includes(nue);
      });
    },
  },
  methods: {
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    refresh() {
      this.$store.dispatch('pai-common/getServices');
      this.$store.dispatch('pai-common/getStatus');
    },
    edit(row) {
      this.currentService = row.namespace + row.name + row.port;
    },
    cancel() {
      this.currentService = '';
      this.refresh();
    },
    async save(row) {
      const service = this.allServices.find((v) => {
        return v.metadata.namespace === row.namespace && v.metadata.name === row.name && v.spec.ports && v.spec.ports.map(v => `${ v.port }`)
          .includes(row.port);
      });

      if (service) {
        const ports = service.spec.ports[service.spec.ports.map(v => `${ v.port }`).indexOf(row.port)];
        const extIP = ports.port;
        const backend = row.backendPort;

        if (!row.backendPort) {
          this.$message.error(this.t('pai.edit.tips.required'));

          return;
        }
        if ((/[!@#$%^&*()>?<";~`|+={}]/).test(row.backendPort)) {
          this.$message.error(this.t('pai.edit.tips.specialCharacters'));

          return;
        }
        this.$set(service.metadata, 'annotations', { [`ext.ipvs/${ row.proto }${ extIP }`]: `${ backend }` });
        this.$message({
          type:    'success',
          message: this.t('pai.lb.tip.success')
        });
      } else {
        this.$message({
          type:    'warning',
          message: this.t('pai.lb.tip.warning')
        });

        return;
      }
      this.currentService = '';
      await service.save();
      // this.$store.dispatch('pai-common/updateServices', { ...this.tableData });
    },
  }
};
</script>

<style lang="scss" scoped>
.filter{
  width: 200px;
  height: 50px;
  float: right;
}

table{
  ::v-deep .el-input__inner{
    height: 25px;
  }
}

.bottom{
  float:right;
  button{
    margin-right: 10px;;
  }
}
</style>
