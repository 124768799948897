import { importTypes } from '@rancher/auto-import';
import { IPlugin } from '@shell/core/types';
import routing from './routing/pai-routing';
import customValidators from './validators';
import './assets/styles/app.scss';
import paiStore from '../pai/store/pai-common';
import paiGrafana from '../pai/store/pai-grafana';
// Init the package
export default function(plugin: IPlugin) {
  // Auto-import model, detail, edit from the folders
  importTypes(plugin);

  // Provide plugin metadata from package.json
  plugin.metadata = require('./package.json');

  // Load a product
  plugin.addProduct(require('./config/pai'));
  plugin.addProduct(require('./config/platform'));
  plugin.addProduct(require('./config/deploy'));

  plugin.addRoutes(routing);

  plugin.validators = customValidators;

  plugin.addDashboardStore(paiStore.config.namespace, paiStore.specifics, paiStore.config);
  plugin.addDashboardStore(paiGrafana.config.namespace, paiGrafana.specifics, paiGrafana.config);
}
